import { PRODUCTS } from 'common/constants'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getSettings } from '../../redux/actions/settingsActions'
import { AppDispatch, GlobalState } from '../../store'

const getDefaultProductName = (entitlementId: string) => {
  if (entitlementId === PRODUCTS.connectIt.id) {
    return PRODUCTS.connectIt.name
  }
  return PRODUCTS.nimbraEdge.name
}

export const PageTitle = ({ title }: { title: string }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { settings } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const [productName, setProductName] = useState('')
  useEffect(() => {
    if (!settings) return

    if (settings.customTabTitle) {
      setProductName(settings.customTabTitle)
      return
    }

    if (settings.entitlements && settings.entitlements.product) {
      setProductName(getDefaultProductName(settings.entitlements.product))
    }
  }, [settings])

  useEffect(() => {
    dispatch(getSettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Helmet>
      <title>
        {title} - {productName}
      </title>
    </Helmet>
  )
}
