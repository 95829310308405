import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'

import { EnrichedInput } from '../../../api/nm-types'
import { TranscodingSection } from './TranscodingSection'
import { MptsDemuxSection } from './MptsDemuxSection'
import { Paper, Select } from '../../../components/common/Form'
import { InputFormProps } from '../Edit/InputForm'
import { useFormikContext } from 'formik'
import { GlobalState } from '../../../store'
import { shallowEqual, useSelector } from 'react-redux'

export interface InputTransformationSectionProps {
  currentParentInput: EnrichedInput
}

export const InputTransformationSection = ({ currentParentInput }: InputTransformationSectionProps) => {
  const [transformation, setTransformation] = useState<'mpts-demux' | 'transcode' | ''>('')
  const form = useFormikContext<InputFormProps>()
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  useEffect(() => {
    if (form.values.deriveFrom?.ingestTransform?.type) {
      setTransformation(form.values.deriveFrom.ingestTransform.type)
    }
  }, [form.values.deriveFrom?.ingestTransform?.type])

  return (
    <Paper className="outlined" title="Transformation">
      <Grid container>
        <Paper>
          <Select
            label="Select transformation"
            name="deriveFrom.ingestTransform.type"
            options={[
              { name: 'MPEG-TS Demultiplex', value: 'mpts-demux' },
              { name: 'Transcode', value: 'transcode', disabled: !devMode },
            ]}
          />

          {transformation === 'mpts-demux' && (
            <Grid item xs={12}>
              <MptsDemuxSection currentParentInput={currentParentInput} />
            </Grid>
          )}
          {transformation === 'transcode' && <TranscodingSection />}
        </Paper>
      </Grid>
    </Paper>
  )
}
