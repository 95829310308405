import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Appliance, Tunnel, TunnelOutputInfo, TunnelType } from 'common/api/v1/types'
import { Api, AppDispatch, useRoutes } from '../../../../store'
import { ExternalLink, Link } from '../../../common/Link'
import { GridItem, Paper } from '../../../common/Form'
import { Table } from '../../../common/Table'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import DataSet from '../../../common/DataSet'
import { useAsync } from 'react-async-hook'
import Pendable from '../../../common/Pendable'
import Grid from '@mui/material/Grid'
import Wrapper from '../../../common/Wrapper'
import { enqueueErrorSnackbar } from '../../../../redux/actions/notificationActions'
import { useDispatch } from 'react-redux'
import { ApplicationException } from '../../../common/ApplicationException'
import Tooltip from '../../../common/Tooltip'
import LocatedDownstream from '@mui/icons-material/ArrowRightAlt'
import InputLocatedHere from '@mui/icons-material/Home'
import Info from '@mui/icons-material/Info'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

const marginBetweenIconAndName = '12px'

const { tunnelApi } = Api

export interface InputsOutputsProps {
  appliance: Appliance
}
const Tunnels: React.FunctionComponent<InputsOutputsProps> = ({ appliance }) => {
  const navigate = useNavigate()
  const routes = useRoutes()

  const titleComponent = (
    <div style={{ display: 'flex', paddingBottom: 16 }}>
      <Typography variant="h2" sx={{ width: '100%' }}>
        Tunnels
      </Typography>
    </div>
  )
  return (
    <Paper title={titleComponent} collapsible collapsed={true}>
      <div style={{ width: '100%' }}>
        <PaginatedList<PaginatedRequestParams, Tunnel>
          api={() => tunnelApi.listTunnels({ appliance: appliance.id })}
          hideSearch
          emptyMessage="no tunnels found"
          notFoundMessage="no matching tunnels"
          List={({ list }) => (
            <Table
              data={list}
              emptyMessageComponent="no tunnels found"
              rowProps={(tunnel) => {
                return {
                  hover: true,
                  sx: { cursor: 'pointer' },
                  onClick: () => navigate(routes.tunnel({ tunnelId: tunnel.id, applianceName: appliance.name })),
                }
              }}
              config={[
                {
                  title: 'Id',
                  getValue: ({ id: tunnelId }) => <span>{tunnelId}</span>,
                },
                {
                  title: 'Connection type',
                  getValue: ({ client }) => <span>{client === appliance.id ? 'client' : 'server'}</span>,
                },
                {
                  title: 'Connected to',
                  getValue: ({ client, clientName, serverName }) => (
                    <span>{client === appliance.id ? serverName : clientName}</span>
                  ),
                },
                {
                  title: 'Number of inputs',
                  getValue: ({ inputs }) => <span>{inputs.length}</span>,
                },
              ]}
            />
          )}
        />
      </div>
    </Paper>
  )
}

export const TunnelDetails = () => {
  const { tunnelId } = useParams()
  const routes = useRoutes()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const queryParams = new URLSearchParams(location.search)
  const applianceName = queryParams.get('applianceName')

  const {
    result: tunnels,
    loading: isFetchingTunnels,
    error: listTunnelsError,
  } = useAsync(() => Api.tunnelApi.listTunnels({ ids: [tunnelId!] }), [tunnelId])

  const tunnel = tunnels?.items.find((t) => t.id.toString() === tunnelId)

  useEffect(() => {
    if (listTunnelsError) {
      dispatch(
        enqueueErrorSnackbar({
          operation: `fetch tunnel ${tunnelId}`,
          error: listTunnelsError as unknown as ApplicationException,
        }),
      )
    }
  }, [dispatch, listTunnelsError, tunnelId])

  const metricsLink = {
    text: 'RIST Overview',
    url: `/grafana/d/rist-overview/rist-overview?orgId=1&refresh=10s&from=now-15m&to=now&var-type=tunnel&var-applianceName=${
      applianceName ?? 'All'
    }`,
  }

  const titleComponent = (
    <div style={{ display: 'flex', paddingBottom: 16 }}>
      <Typography variant="h2" sx={{ width: '50%' }}>
        Inputs
      </Typography>
      <Typography variant="h2" sx={{ width: '50%' }}>
        Outputs
      </Typography>
    </div>
  )

  return (
    <Wrapper name="Tunnel">
      <Grid container spacing={0}>
        <Pendable cover pending={isFetchingTunnels}>
          {tunnel && (
            <>
              <Paper title="Tunnel info">
                <GridItem lg={12} xl={12}>
                  <DataSet
                    values={{
                      Id: tunnel.id,
                      Type: TunnelType[tunnel.type],
                      'Client appliance': (
                        <Link to={routes.appliancesUpdate({ id: tunnel.client })} underline="hover">
                          {tunnel.clientName}
                        </Link>
                      ),
                      'Client local address': `${tunnel.clientLocalIp}:${tunnel.clientLocalPort}`,
                      'Client remote address': `${tunnel.clientRemoteIp}:${tunnel.clientRemotePort}`,
                      'Client interface': `${tunnel.clientPhysicalPort.name}`,
                      'Server appliance': (
                        <Link to={routes.appliancesUpdate({ id: tunnel.server })} underline="hover">
                          {tunnel.serverName}
                        </Link>
                      ),
                      'Server local address': `${tunnel.serverLocalIp}:${tunnel.serverLocalPort}`,
                      'Server public ip': `${tunnel.serverPublicIp}`,
                      'Server interface': `${tunnel.serverPhysicalPort.name}`,
                      Network: tunnel.network ? (
                        <Link to={routes.networkUpdate({ id: tunnel.network.id })} underline="hover">
                          {tunnel.network.name}
                        </Link>
                      ) : (
                        'N/A'
                      ),

                      Metrics: (
                        <ExternalLink
                          key={metricsLink.url}
                          href={metricsLink.url}
                          underline="always"
                          style={{ marginRight: '12px' }}
                        >
                          {metricsLink.text}
                        </ExternalLink>
                      ),
                    }}
                  />
                </GridItem>
              </Paper>

              <Paper title={titleComponent} collapsible>
                <div style={{ width: '50%' }}>
                  <Table
                    data={tunnel.inputs}
                    emptyMessageComponent="Tunnel is not routing any inputs"
                    noHeader
                    rowProps={({ id: inputId }) => {
                      return {
                        hover: true,
                        sx: { cursor: 'pointer' },
                        onClick: () => navigate(routes.inputsUpdate({ id: inputId })),
                      }
                    }}
                    config={[
                      {
                        getValue: ({ name, id: inputId }) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flex: 1,
                            }}
                          >
                            <Link
                              underline="hover"
                              to={routes.inputsUpdate({ id: inputId })}
                              style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                              muiStyle={{
                                display: 'flex',
                                flex: 1,
                                gap: marginBetweenIconAndName,
                              }}
                            >
                              <span style={{ display: 'flex', flex: 1 }}>{name}</span>
                            </Link>
                            <IconButton
                              aria-label="service overview button"
                              onClick={(e) => {
                                e.stopPropagation()
                                navigate(routes.service({ id: inputId }))
                              }}
                            >
                              <Tooltip title={'Service Overview'} disablePortal={false}>
                                <Info />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        ),
                      },
                    ]}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <PaginatedList<PaginatedRequestParams, TunnelOutputInfo>
                    api={(params) => tunnelApi.listTunnelOutputs(tunnel.id, params)}
                    hideSearch
                    emptyMessage="Tunnel is not routing any outputs"
                    notFoundMessage="No matching outputs"
                    List={({ list }) => (
                      <Table
                        data={list}
                        emptyMessageComponent="Tunnel is not routing any outputs"
                        noHeader
                        config={[
                          {
                            getValue: ({ outputName, outputId, inputId, finalTunnelId }) => {
                              const isDownstreamTunnel = tunnel.id !== finalTunnelId
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                  }}
                                >
                                  <Link
                                    underline="hover"
                                    to={routes.outputsUpdate({ id: outputId })}
                                    style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
                                    muiStyle={{
                                      display: 'flex',
                                      flex: 1,
                                      gap: marginBetweenIconAndName,
                                    }}
                                  >
                                    <Tooltip
                                      disablePortal={false}
                                      title={
                                        isDownstreamTunnel
                                          ? `This output is served by downstream tunnel ${finalTunnelId} (routed via this tunnel)`
                                          : 'This output is served by this tunnel'
                                      }
                                    >
                                      {isDownstreamTunnel ? <LocatedDownstream /> : <InputLocatedHere />}
                                    </Tooltip>
                                    <span style={{ display: 'flex', flex: 1 }}>{outputName}</span>
                                  </Link>
                                  <IconButton
                                    aria-label="service overview button"
                                    disabled={!inputId}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      navigate(routes.service({ id: inputId, outputId }))
                                    }}
                                  >
                                    <Tooltip title={'Service Overview'} disablePortal={false}>
                                      <Info />
                                    </Tooltip>
                                  </IconButton>
                                </Box>
                              )
                            },
                          },
                        ]}
                      />
                    )}
                  />
                </div>
              </Paper>
            </>
          )}
          {!tunnel && !isFetchingTunnels && (
            <Paper>
              <Typography>{`Could not find tunnel with id ${tunnelId}`}</Typography>
            </Paper>
          )}
        </Pendable>
      </Grid>
    </Wrapper>
  )
}

export default Tunnels
