import { createSlice } from '@reduxjs/toolkit'

import {
  closeSnackbar,
  enqueueSnackbar,
  removeSnackbar,
  Notification,
  closeAllSnackbars,
} from '../actions/notificationActions'

type State = Array<Notification>

const initialNotifications: State = []

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialNotifications,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        enqueueSnackbar,
        (state, { payload: notification }): State => [
          ...state.map((item) => {
            if (item.type === 'newVersionAvailableSnackbar') {
              return item
            }
            return { ...item, dismissed: true }
          }),
          {
            ...notification,
            options: {
              ...notification.options,
              key: `${new Date().getTime()} ${Math.random()}`,
            },
          },
        ],
      )
      .addCase(
        closeSnackbar,
        (state, { payload: key }): State =>
          state.map((item) => (item.options.key === key ? { ...item, dismissed: true } : { ...item })),
      )
      .addCase(closeAllSnackbars, (state): State => state.map((item) => ({ ...item, dismissed: true })))
      .addCase(removeSnackbar, (state, { payload: key }): State => state.filter((item) => item.options.key !== key))
  },
})

export default notificationsSlice.reducer
