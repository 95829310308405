import Typography from '@mui/material/Typography'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'

const classes = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}

interface DialogTitleProps {
  title?: string
  onClose?: () => void
}
/**
 * Simple title for modal dialogs
 * @param title - title itself
 * @param onClose - callback to call if the 'close' button clicked (hidden if no callback provided)
 */
const DialogTitle = ({ title, onClose }: DialogTitleProps) => {
  return (
    <MuiDialogTitle sx={classes.root}>
      <Typography variant="h2" component="span">
        {title || null}
      </Typography>
      {!!onClose && (
        <IconButton aria-label="close" onClick={onClose} edge="end">
          <Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
