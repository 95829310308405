import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import FaviconPng from './img/favicon.png'
import { AppDispatch, GlobalState } from './store'
import { SnackbarProvider, Snackbar } from './components/common/Snackbar'
import { Login } from './components/login'
import LoggedIn from './components/loggedin'
import { ImageName, User } from 'common/api/v1/types'
import { init } from './redux/actions/uiActions'
import { ThemeSwitcher } from './ThemeSwitcher'
import { useFetchedImage } from './components/common/LogoFetcher'

/**
 * Used just to add navigate to store middleware to use in redux epics
 */
const Initializer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(init(navigate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  return null
}

function App() {
  const { source, type } = useFetchedImage(ImageName.favicon)

  useEffect(() => {
    if (source === '') return // fetch not complete yet

    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      if (source === null) {
        // use default
        link.href = FaviconPng
        link.type = 'image/png'
      } else {
        link.href = source
        link.type = type
      }
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }, [source])

  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user, shallowEqual) as User
  const isLoggedIn = 'id' in user
  return (
    <ThemeSwitcher>
      <Initializer />

      <SnackbarProvider>
        <Snackbar />
        {isLoggedIn ? <LoggedIn /> : <Login />}
      </SnackbarProvider>
    </ThemeSwitcher>
  )
}

export default App
