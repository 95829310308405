import {
  ApplianceType,
  ExternalRegionMode,
  type ListResult,
  type Region,
  type RegionFilter,
  type RegionSortableField,
  type ScaleOperation,
  type ScaleStatus,
} from 'common/api/v1/types'
import { EdgeClient } from '../../../../common/src/generated/edgeClient'
import { PaginatedRequestParams, singleSortQueryFromPaginatedRequestParams, type EnrichedRegion } from '../nm-types'

export interface RegionDetails {
  region: EnrichedRegion
  videoScale: ScaleStatus
  thumbScale: ScaleStatus
  appliancesTotal: number
}

export interface IRegionApi {
  getRegions(params: PaginatedRequestParams, onlyVideoRegions?: boolean): Promise<ListResult<EnrichedRegion>>
  getRegionDetailsById(id: string): Promise<RegionDetails>
  setVideoScale(regionId: string, count: number): Promise<ScaleOperation>
  setThumbScale(regionId: string, count: number): Promise<ScaleOperation>
  deleteRegion(regionId: Region['id']): Promise<void>
}

export class RegionApi implements IRegionApi {
  constructor(private readonly edgeClient: EdgeClient) {}

  #externalToText(external: Region['external']): EnrichedRegion['_externalText'] {
    switch (external) {
      case ExternalRegionMode.core:
        return 'core'
      case ExternalRegionMode.externalK8s:
        return 'external (kubernetes)'
      case ExternalRegionMode.external:
        return 'external'
      default:
        return 'N/A'
    }
  }

  async getRegions(
    { filter: searchName, ...params }: PaginatedRequestParams<RegionSortableField>,
    onlyVideoRegions: boolean = false,
  ): Promise<ListResult<Region>> {
    const filter: RegionFilter = { searchName, onlyVideoRegions }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    const res = await this.edgeClient.listRegions(query)
    return {
      ...res,
      items: res.items.map((r) => ({
        ...r,
        _externalText: this.#externalToText(r.external),
      })),
    }
  }

  async getRegionDetailsById(id: Region['id']): Promise<RegionDetails> {
    const region = await this.edgeClient.getRegion(id)
    const [total, videoScale, thumbScale] =
      region.external !== ExternalRegionMode.external
        ? await Promise.all([
            this.edgeClient.listAppliances({ filter: { regionNames: [region.name] } }).then((res) => res.total),
            this.edgeClient.getRegionVideoScale(id).then((res) => ({
              replicas: res.replicas ?? res.currentReplicas,
              currentReplicas: res.currentReplicas,
            })),
            this.edgeClient.getRegionThumbScale(id).then((res) => ({
              replicas: res.replicas ?? res.currentReplicas,
              currentReplicas: res.currentReplicas,
            })),
          ])
        : await Promise.all([
            this.edgeClient.listAppliances({ filter: { regionNames: [region.name] } }).then((res) => res.total),
            this.edgeClient.listAppliances({ filter: { region: id, type: ApplianceType.core } }).then((res) => ({
              currentReplicas: res.total,
            })),
            this.edgeClient.listAppliances({ filter: { region: id, type: ApplianceType.thumb } }).then((res) => ({
              currentReplicas: res.total,
            })),
          ])

    return {
      region: {
        ...region,
        _externalText: this.#externalToText(region.external),
      },
      appliancesTotal: total,
      videoScale,
      thumbScale,
    }
  }

  setVideoScale(regionId: string, count: number): Promise<ScaleOperation> {
    return this.edgeClient.setRegionVideoScale(regionId, { count, waitSeconds: 1 })
  }
  setThumbScale(regionId: string, count: number): Promise<ScaleOperation> {
    return this.edgeClient.setRegionThumbScale(regionId, { count, waitSeconds: 1 })
  }

  async deleteRegion(regionId: Region['id']): Promise<void> {
    await this.edgeClient.deleteRegion(regionId)
  }
}
