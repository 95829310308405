import get from 'lodash/get'

import { Autocomplete } from './Form'
import { useField, useFormikContext } from 'formik'
import {
  getLocalPortNumbersUsedByInputPort,
  getLocalPortNumbersUsedByInputProtocol,
  getLocalPortNumbersUsedByOutputPort,
  getLocalPortNumbersUsedByOutputProtocol,
  isTcpBasedProtocol,
  isUdpBasedProtocol,
  TCP_EDGE_CONTROL_HTTP_PORT,
  UDP_HANDOVER_PORT_RANGE_END,
  UDP_HANDOVER_PORT_RANGE_START,
  UDP_TUNNEL_PORT_RANGE_END,
  UDP_TUNNEL_PORT_RANGE_START,
} from 'common/ports'
import { Input, InputPort, IpPortMode, OccupiedPort, Output, OutputPort } from 'common/api/v1/types'
import { generateNumericRange } from 'common/arrays'
import { listResult, PortToValidate } from '../../utils'
import { useCallback, useEffect, useState } from 'react'
import { PaginatedRequestParams } from '../../api/nm-types'

interface Props {
  name: string
  label: string
  namePrefix: string
  required: boolean
  portInfo: PortToValidate
}

const reservedUdpPortNumbers = [
  ...generateNumericRange(UDP_HANDOVER_PORT_RANGE_START, UDP_HANDOVER_PORT_RANGE_END),
  ...generateNumericRange(UDP_TUNNEL_PORT_RANGE_START, UDP_TUNNEL_PORT_RANGE_END),
]
const reservedTcpPortNumbers = [TCP_EDGE_CONTROL_HTTP_PORT]

export function suggestAvailablePortNumbers({
  rangeStart,
  requestedMode,
  logicalPortsOnSameNic,
}: {
  rangeStart?: number
  requestedMode: IpPortMode
  logicalPortsOnSameNic: OccupiedPort[]
}) {
  const isUdp = isUdpBasedProtocol(requestedMode)
  const isTcp = isTcpBasedProtocol(requestedMode)
  const excludedPortNumbers = new Set(isUdp ? reservedUdpPortNumbers : isTcp ? reservedTcpPortNumbers : [])
  logicalPortsOnSameNic.forEach((lp) => {
    const canConflict = (isUdp && isUdpBasedProtocol(lp.portMode)) || (isTcp && isTcpBasedProtocol(lp.portMode))
    if (canConflict) {
      lp.localPortNumbers.forEach((p) => excludedPortNumbers.add(p))
    }
  })

  const DEFAULT_START_RANGE = 10_000
  let suggestedStartRange = Math.abs(parseInt((rangeStart || DEFAULT_START_RANGE).toString()))
  if (!suggestedStartRange || isNaN(suggestedStartRange)) suggestedStartRange = DEFAULT_START_RANGE
  while (suggestedStartRange < 1000) suggestedStartRange *= 10
  const minAllowedPort = 1024
  const maxAllowedPort = 65535
  const startRange = Math.min(maxAllowedPort, Math.max(minAllowedPort, suggestedStartRange))
  const maxSuggestions = 5
  const suggestedPortNumbers = new Set<number>()
  for (const portNumber of generateNumericRange(startRange, maxAllowedPort)) {
    // Certain services such as FEC-enabled RTP inputs need multiple ports. An offset by 6 should be safe.
    const isDivisibleBySix = portNumber % 6 === 0
    const isNearbyPortOccupied = generateNumericRange(-5, 5).some((offset) =>
      excludedPortNumbers.has(portNumber + offset),
    )
    if (isDivisibleBySix && !isNearbyPortOccupied) {
      suggestedPortNumbers.add(portNumber)
    }
    if (suggestedPortNumbers.size === maxSuggestions) {
      break
    }
  }
  return Array.from(suggestedPortNumbers)
}

// Returns the logical ports in formik.values for the Input/Output forms.
function useRequestedPortsOnSameNic(namePrefix: string, isInput: boolean): OccupiedPort[] {
  const { values } = useFormikContext<Input | Output>()

  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2) // _applianceSection-xxx.ports.0 --> _applianceSection-xxx.ports
  const port: InputPort | OutputPort = get(values, namePrefix)
  const ports: InputPort[] | OutputPort[] = get(values, portsNamePrefix) || []
  const otherPortsOnSamePhysicalPort = ports.filter((p) => p !== port && p.physicalPort === port.physicalPort)

  function fetchLogicalPortsFromFormik() {
    return otherPortsOnSamePhysicalPort.map((p) => ({
      portMode: p.mode,
      logicalPortId: p.id ?? '',
      physicalPortId: p.physicalPort,
      inputId: isInput ? values.id : undefined,
      outputId: isInput ? undefined : values.id,
      localPortNumbers: isInput
        ? getLocalPortNumbersUsedByInputPort(p as InputPort)
        : getLocalPortNumbersUsedByOutputPort(p as OutputPort),
    }))
  }

  const [requestedLogicalPorts, setRequestedLogicalPorts] = useState(fetchLogicalPortsFromFormik())
  useEffect(
    () => setRequestedLogicalPorts(fetchLogicalPortsFromFormik()),
    [JSON.stringify(otherPortsOnSamePhysicalPort)],
  )
  return requestedLogicalPorts
}

export const SuggestedLocalPortTextField = ({ name, label, namePrefix, portInfo, required }: Props) => {
  const [field] = useField(name)
  const selectedBasePort = parseInt(field.value) || undefined
  const localPortsUsed = selectedBasePort
    ? portInfo.isInput
      ? getLocalPortNumbersUsedByInputProtocol({
          ...portInfo,
          localBasePort: selectedBasePort,
          isFec: portInfo.isFec ?? false,
          isMulticast: portInfo.isMulticast ?? false,
          isRtcp: portInfo.isRtpRtcp ?? false,
        })
      : getLocalPortNumbersUsedByOutputProtocol({ ...portInfo, localBasePort: selectedBasePort })
    : []
  const comment =
    localPortsUsed.length > 0 ? `The following local ports will be used: ${localPortsUsed.join(', ')}` : ''

  const requestedPortsOnSameNic: OccupiedPort[] = useRequestedPortsOnSameNic(namePrefix, portInfo.isInput)
  const logicalPortsOnSameNic = [...requestedPortsOnSameNic, ...portInfo.existingLogicalPortsOnSameNic]
  const api = useCallback(
    (params: PaginatedRequestParams<any>) => {
      const rangeStart = Number(params.filter)
      return Promise.resolve(
        listResult(
          suggestAvailablePortNumbers({
            rangeStart,
            requestedMode: portInfo.mode,
            logicalPortsOnSameNic,
          }),
        ),
      )
    },
    [JSON.stringify(logicalPortsOnSameNic)],
  )

  return (
    <Autocomplete<number>
      name={name}
      label={label}
      type={'number'}
      required={required}
      groupBy={() => 'Suggested available ports:'}
      api={api}
      validators={{
        port: {
          disallowInternal: true,
          isUdp: isUdpBasedProtocol(portInfo.mode),
          even: portInfo.mode === IpPortMode.rist,
        },
        isPortAvailable: { ...portInfo, existingLogicalPortsOnSameNic: logicalPortsOnSameNic },
      }}
      comment={comment}
      formik={useFormikContext()}
      defaultOption={selectedBasePort}
      getOptionValue={(option) => Number(option)}
      getOptionLabel={(option) => option.toString()}
      optionComparator={(o1, o2) => Number(o1) === Number(o2)}
      autoSelect={false}
      allowCustomOptions={true}
      disableClearable={true}
    />
  )
}
