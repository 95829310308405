import { useEffect } from 'react'
import { FormikProps } from 'formik'
import get from 'lodash/get'

import {
  Address,
  ApplianceFeatures,
  ApplianceType,
  IpPortMode,
  OccupiedPort,
  Output,
  OutputAdminStatus,
  SrtKeylen,
  SrtMode,
  SrtOutputPort,
  SrtRateLimiting,
} from 'common/api/v1/types'

import { Select, TextInput } from '../../../../common/Form'
import {
  createDefaultFiledValues,
  isCoreNode,
  makeAddressOptions,
  PortToValidate,
  usePrevious,
} from '../../../../../utils'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { WhitelistCidrBlockAutoComplete } from '../../../../common/WhitelistCidrBlockAutoComplete'
import { SuggestedLocalPortTextField } from '../../../../common/SuggestedPortTextField'
import { SrtBondingMode, srtBondingOptions } from '../../../../../api/nm-types'

export type SrtOutput = Output & { ports: SrtOutputPort[] }

const LATENCY_MAX = 6 * 60 * 1000
const TTL_MAX = 255
const MTU_MIN = 76

export enum SrtFields {
  srtMode = 'srtMode',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  localIp = 'localIp',
  localPort = 'localPort',
  rateLimiting = 'rateLimiting',
  maxBw = 'maxBw',
  inputBw = 'inputBw',
  oheadBw = 'oheadBw',
  latency = 'latency',
  pbkeylen = 'pbkeylen',
  passphrase = 'passphrase',
  ipttl = 'ipttl',
  mss = 'mss',
  streamId = 'streamId',
  whitelistCidrBlock = 'whitelistCidrBlock',
  bondingMode = 'bondingMode',
  failoverPriority = 'failoverPriority',
}

export const srtDefaults = (numDistinctFailoverPriorities: number) =>
  createDefaultFiledValues(Object.keys(SrtFields), [], {
    [SrtFields.latency]: 120,
    [SrtFields.pbkeylen]: SrtKeylen.none,
    [SrtFields.oheadBw]: 25,
    [SrtFields.rateLimiting]: SrtRateLimiting.notEnforced,
    [SrtFields.whitelistCidrBlock]: [],
    [SrtFields.bondingMode]:
      numDistinctFailoverPriorities === 0
        ? SrtBondingMode.none
        : numDistinctFailoverPriorities > 1
        ? SrtBondingMode.activeBackup
        : SrtBondingMode.activeActive,
  })
export const getSrtFieldsToSave = (port: SrtOutputPort) => [
  SrtFields.srtMode,
  SrtFields.latency,
  SrtFields.rateLimiting,
  SrtFields.pbkeylen,
  SrtFields.ipttl,
  SrtFields.mss,
  SrtFields.streamId,
  ...(port.pbkeylen === SrtKeylen.none ? [] : [SrtFields.passphrase]),
  ...(function () {
    switch (port.srtMode) {
      case SrtMode.listener:
        return [SrtFields.localIp, SrtFields.localPort, SrtFields.whitelistCidrBlock, SrtFields.failoverPriority]
      case SrtMode.caller:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localPort, SrtFields.failoverPriority]
      default:
        return [SrtFields.remoteIp, SrtFields.remotePort, SrtFields.localIp, SrtFields.whitelistCidrBlock]
    }
  })(),
  ...(function () {
    switch (port.rateLimiting) {
      case SrtRateLimiting.absolute:
        return [SrtFields.maxBw]
      case SrtRateLimiting.relativeToInput:
        return [SrtFields.inputBw, SrtFields.oheadBw]
      default:
        return []
    }
  })(),
]

interface SrtFormProps {
  form: FormikProps<SrtOutput>
  index: number
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: OutputAdminStatus
  applianceType: ApplianceType
  applianceFeatures: ApplianceFeatures
  onAddLogicalPortRequested: () => void
  onRemoveLogicalPortRequested: () => void
}

const SrtForm = ({
  form: { values, setFieldValue },
  index,
  addresses,
  namePrefix,
  occupiedPorts,
  adminStatus,
  applianceType,
  applianceFeatures,
  onAddLogicalPortRequested,
  onRemoveLogicalPortRequested,
}: SrtFormProps) => {
  const portsNamePrefix = namePrefix.substr(0, namePrefix.length - 2)
  const ports: SrtOutputPort[] = get(values, portsNamePrefix)
  const previousNumberOfPorts = usePrevious(ports.length)
  const firstPort = ports[0] as SrtOutputPort & { [SrtFields.bondingMode]: SrtBondingMode | undefined }
  const secondPort: SrtOutputPort | undefined = ports[1]
  const currentPort: SrtOutputPort | undefined = ports[index]
  const isBondingEnabled = Boolean(firstPort.bondingMode) && firstPort.bondingMode !== SrtBondingMode.none
  const previousIsBondingEnabled = usePrevious(isBondingEnabled)

  const localAddressSelector = `${namePrefix}.${SrtFields.localIp}`
  const localPortSelector = `${namePrefix}.${SrtFields.localPort}`
  const supportedModes = applianceFeatures.output?.modes.find((m) => m.mode === IpPortMode.srt)?.subModes ?? []

  const currentPortInfo: PortToValidate = {
    isInput: false,
    isPortDisabled: adminStatus === OutputAdminStatus.off,
    mode: currentPort.mode,
    existingLogicalPortsOnSameNic: occupiedPorts,
  }

  useEffect(() => {
    // Ensure that the second srt mode mirrors the first if bonding is enabled
    if (index === 1 && firstPort && secondPort) {
      if (firstPort.srtMode !== secondPort.srtMode && isBondingEnabled) {
        setFieldValue(`${portsNamePrefix}.1.srtMode`, firstPort.srtMode, false)
      }
    }
  }, [index, firstPort, secondPort, isBondingEnabled, setFieldValue, portsNamePrefix])

  // Effect that ensures a valid bonding mode is selected
  useEffect(() => {
    if (index > 0) return

    function ensureCorrectBondingMode() {
      let newBondingMode: SrtBondingMode | undefined = undefined
      if (isBondingEnabled) {
        if (firstPort.srtMode === SrtMode.rendezvous) {
          // Ensure that bonding mode is none if rendezvous is chosen
          newBondingMode = SrtBondingMode.none
        } else if (firstPort.srtMode === SrtMode.listener && firstPort.bondingMode === SrtBondingMode.activeBackup) {
          // Ensure that bonding mode is not active/backup if listener is chosen
          newBondingMode = SrtBondingMode.activeActive
        }
        if (ports.length === 1) {
          // Bonding is enabled but only one logical port
          const didDeletePort = previousNumberOfPorts !== undefined ? ports.length < previousNumberOfPorts : false
          if (didDeletePort) {
            // User just deleted the second logical port - disable bonding
            newBondingMode = SrtBondingMode.none
          }
        }
      }
      if (newBondingMode !== undefined && firstPort.bondingMode !== newBondingMode) {
        setFieldValue(`${portsNamePrefix}.0.bondingMode`, newBondingMode, false)
      }
    }

    ensureCorrectBondingMode()
  }, [isBondingEnabled, index, firstPort, ports, previousNumberOfPorts, setFieldValue])

  // Effect that adds/removes logical ports - triggers every time bonding mode is enabled/disabled (either by user or by another effect).
  useEffect(() => {
    if (isBondingEnabled === previousIsBondingEnabled) return
    // User enabled/disabled bonding

    function ensureCorrectNumberLogicalPorts() {
      if (isBondingEnabled) {
        if (ports.length === 1 && index === 0) {
          // User enabled bonding, but only one logical port - automatically add a logical port (two ports are required for bonding)
          onAddLogicalPortRequested()
        }
      } else {
        if (index > 0) {
          // Cannot have multiple ports with bonding disabled
          onRemoveLogicalPortRequested()
        }
      }
    }

    ensureCorrectNumberLogicalPorts()
  }, [
    isBondingEnabled,
    previousIsBondingEnabled,
    ports,
    onAddLogicalPortRequested,
    index,
    onRemoveLogicalPortRequested,
  ])

  return (
    <>
      <Select
        name={`${namePrefix}.${SrtFields.srtMode}`}
        label="Connection mode"
        options={supportedModes}
        disabled={index !== 0 && isBondingEnabled}
        required
      />
      {index === 0 && (
        <Select
          name={`${namePrefix}.${SrtFields.bondingMode}`}
          label="Bonding mode"
          disabled={firstPort && (firstPort.srtMode === SrtMode.rendezvous || !firstPort.srtMode)}
          options={srtBondingOptions(firstPort.srtMode)}
          required
        />
      )}
      {currentPort && [SrtMode.caller, SrtMode.rendezvous].includes(currentPort.srtMode) && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.remoteIp}`}
            label="Remote host"
            newLine
            required
            validators={{ ipOrHostname: {} }}
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.remotePort}`}
            label={currentPort?.srtMode === SrtMode.rendezvous ? 'Local and remote port' : 'Remote port'}
            required
            type="number"
            noNegative
            validators={{
              port: { isUdp: true },
              isPortAvailable: {
                ...currentPortInfo,
                existingLogicalPortsOnSameNic: currentPort.srtMode === SrtMode.rendezvous ? occupiedPorts : [],
              },
            }}
          />
        </>
      )}
      {currentPort.srtMode && [SrtMode.listener, SrtMode.rendezvous].includes(currentPort.srtMode) && (
        <Select
          name={localAddressSelector}
          label="Local address"
          required
          options={makeAddressOptions(get(values, localAddressSelector), addresses)}
          newLine
          validators={{
            addressIn: { addresses },
          }}
        />
      )}
      {[SrtMode.listener, SrtMode.caller].includes(currentPort?.srtMode) && (
        <SuggestedLocalPortTextField
          name={localPortSelector}
          label={currentPort.srtMode === SrtMode.listener ? 'Local port' : 'Local outgoing port'}
          required={currentPort.srtMode === SrtMode.listener}
          namePrefix={namePrefix}
          portInfo={currentPortInfo}
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.rateLimiting}`}
        label="Output rate limiting"
        options={Object.values(SrtRateLimiting)}
        required
        newLine
      />
      {currentPort.rateLimiting === SrtRateLimiting.absolute && (
        <TextInput
          name={`${namePrefix}.${SrtFields.maxBw}`}
          label="Maximum bandwidth (Mbps)"
          type="number"
          required
          noNegative
        />
      )}
      {currentPort.rateLimiting === SrtRateLimiting.relativeToInput && (
        <>
          <TextInput
            name={`${namePrefix}.${SrtFields.inputBw}`}
            label="Input bandwidth (Mbps)"
            required
            tooltip="Set to '0' for automatic input bandwidth sensing"
            type="number"
            noNegative
          />
          <TextInput
            name={`${namePrefix}.${SrtFields.oheadBw}`}
            label="Overhead bandwidth (%)"
            tooltip="The allowed overhead of output bandwidth in relation to input bandwidth. Minimum 5%"
            required
            type="number"
            noNegative
            validators={{
              number: {
                greaterThanOrEqualTo: 5,
                lessThanOrEqualTo: 100,
                message: 'Must be 5 - 100',
              },
            }}
          />
        </>
      )}
      <TextInput
        name={`${namePrefix}.${SrtFields.latency}`}
        label="Retransmission buffer (ms)"
        type="number"
        noNegative
        required
        newLine
        tooltip="Contains packets that may need to be retransmitted. Shall be at least the same size as the retransmission buffer on the receiver."
        validators={{
          number: {
            lessThanOrEqualTo: LATENCY_MAX,
            message: `Must be 0 - ${LATENCY_MAX}`,
          },
        }}
      />
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.ipttl}`}
          label="TTL"
          type="number"
          noNegative
          tooltip='Defines IP socket "time to live" option.'
          validators={{
            number: {
              lessThanOrEqualTo: TTL_MAX,
              message: `Must be no more than ${TTL_MAX}`,
            },
          }}
        />
      )}
      {!isVaApplianceType(applianceType) && (
        <TextInput
          name={`${namePrefix}.${SrtFields.mss}`}
          label="MTU"
          type="number"
          noNegative
          newLine
          tooltip="MTU size"
          validators={{
            number: {
              greaterThanOrEqualTo: MTU_MIN,
              message: `Must be more than ${MTU_MIN}`,
            },
          }}
        />
      )}
      <Select
        name={`${namePrefix}.${SrtFields.pbkeylen}`}
        label="Encryption type"
        options={Object.entries(SrtKeylen).map(([name, value]) => ({ name, value }))}
        required
        newLine
      />
      {!!currentPort.pbkeylen && currentPort.pbkeylen !== SrtKeylen.none && (
        <TextInput
          name={`${namePrefix}.${SrtFields.passphrase}`}
          label="Passphrase"
          tooltip="Same passphrase must be configured at SRT Input to decrypt the stream"
          validators={{
            noAmpersand: {},
            len: {
              minimum: 10,
              maximum: 79,
            },
          }}
        />
      )}
      {currentPort?.srtMode === SrtMode.caller && (
        <TextInput name={`${namePrefix}.${SrtFields.streamId}`} label="Stream Id" newLine tooltip="Stream ID" />
      )}

      {!isVaApplianceType(applianceType) &&
        currentPort.srtMode &&
        [SrtMode.listener, SrtMode.rendezvous].includes(currentPort.srtMode) && (
          <WhitelistCidrBlockAutoComplete
            name={`${namePrefix}.${SrtFields.whitelistCidrBlock}`}
            required={isCoreNode(applianceType)}
          />
        )}
    </>
  )
}

export default SrtForm
