import { Route, Routes } from 'react-router-dom'

import { SUB_PATH } from '../../utils/routes'
import { NotFound } from '../common/NotFound'
import { List } from './List'
import { Edit } from './Edit'

export const Regions = () => {
  return (
    <Routes>
      <Route index element={<List />} />
      <Route path={SUB_PATH.UPDATE} element={<Edit />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
