import { FormikProps } from 'formik'
import get from 'lodash/get'

import {
  ApplianceFeatures,
  type EncoderSettings,
  isGeneralEncoderSettings,
  isMatroxEncoderConfig,
  MatroxEncoderConfig,
  MatroxPortMode,
  PortMode,
} from 'common/api/v1/types'
import MatroxEncoderSettingsComp from './MatroxEncoderSettings'
import GeneralEncoderDecoderSettings from '../../../common/GeneralEncoderDecoderSettings'
import { EnrichedInputPort } from '../../../../api/nm-types'
import { useEffect } from 'react'
import { makeDefaultMatroxEncoderConfig, makeDefaultVaEncoderSettings } from 'common/encoderSettingsUtil'

function isCorrectEncoderSettingsTypeForPortMode(
  encoderSettings: EncoderSettings | undefined,
  mode: PortMode,
  applianceFeatures: ApplianceFeatures,
) {
  if (mode === MatroxPortMode.matroxSdi) {
    return encoderSettings && isMatroxEncoderConfig(encoderSettings)
  } else {
    const modesWithEncoderSettings = applianceFeatures.input?.modes.filter((m) => !!m.encoder).map((m) => m.mode) ?? []
    if (modesWithEncoderSettings.includes(mode)) {
      return encoderSettings && isGeneralEncoderSettings(encoderSettings)
    }
  }
  // Port mode has no support for encoder settings - should be undefined
  return encoderSettings === undefined
}

function makeEncoderSettings(mode: PortMode, applianceFeatures: ApplianceFeatures) {
  const isMatroxMode = mode === MatroxPortMode.matroxSdi
  const modesWithEncoderSettings = applianceFeatures.input?.modes.filter((m) => !!m.encoder).map((m) => m.mode) ?? []
  const hasEncoderSettings = modesWithEncoderSettings.includes(mode)
  return isMatroxMode
    ? makeDefaultMatroxEncoderConfig()
    : hasEncoderSettings
    ? makeDefaultVaEncoderSettings()
    : undefined
}

interface Props {
  form: FormikProps<any>
  namePrefix: string
  applianceFeatures: ApplianceFeatures
}
const EncoderSettings = ({ form, namePrefix, applianceFeatures }: Props) => {
  const { values, setFieldValue } = form
  const logicalPort: EnrichedInputPort = get(values, namePrefix)
  const physicalPort = logicalPort._port
  const encoderFeatures = applianceFeatures.input?.modes.find((m) => m.mode === logicalPort.mode)?.encoder
  const encoderSettings = (logicalPort as any).encoderSettings

  useEffect(() => {
    if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, logicalPort.mode, applianceFeatures)) {
      const newEncoderSettings = makeEncoderSettings(logicalPort.mode, applianceFeatures)
      setFieldValue(`${namePrefix}.encoderSettings`, newEncoderSettings, false)
    }
  }, [encoderSettings, logicalPort.mode, applianceFeatures, setFieldValue, namePrefix])

  const isDerivedInput = (values as typeof values & { _derived: boolean })._derived
  if (isDerivedInput) {
    return null
  }
  if (!encoderFeatures) {
    return null
  }
  if (!encoderSettings) {
    return null
  }
  if (!isCorrectEncoderSettingsTypeForPortMode(encoderSettings, logicalPort.mode, applianceFeatures)) {
    return null
  }

  return (
    <>
      {logicalPort.mode !== MatroxPortMode.matroxSdi && (
        <GeneralEncoderDecoderSettings
          namePrefix={`${namePrefix}.encoderSettings`}
          encoderFeatures={encoderFeatures}
          setFieldValue={setFieldValue}
        />
      )}
      {logicalPort.mode === MatroxPortMode.matroxSdi && !!physicalPort && (
        <MatroxEncoderSettingsComp
          namePrefix={`${namePrefix}.encoderSettings`}
          physicalPort={physicalPort}
          matroxSettings={encoderSettings as MatroxEncoderConfig}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  )
}

export default EncoderSettings
