import { useCallback, useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, FormikProps, useFormikContext } from 'formik'
import { format } from 'date-fns'
import get from 'lodash/get'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import NonFormCheckbox from '@mui/material/Checkbox'
import Info from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'

import {
  ApplianceType,
  BroadcastStandard,
  ExpFeatures,
  IpPortMode,
  PhysicalPort,
  Region,
  ThumbnailMode,
  VideoPreviewMode,
} from 'common/api/v1/types'
import { isEditableGroup, pluralizeWord, useUser } from '../../../utils'
import { Api, AppDispatch, GlobalState, useRoutes } from '../../../store'
import {
  Autocomplete,
  ButtonsPane,
  Checkbox,
  FormikErrorFocus,
  GridItem,
  Paper,
  SafeRouting,
  Select,
  TextInput,
} from '../../common/Form'

import { CheckboxProps } from '../../common/Form/Checkbox'
import DataSet from '../../common/DataSet'
import { AutoUpdatingInputHealthIndicator } from '../../common/Indicator'
import { Link } from '../../common/Link'

import {
  CommonFields,
  generatorDefaults,
  ristDefaults,
  rtpDefaults,
  srtDefaults,
  udpDefaults,
  zixiDefaults,
} from './PortForm/IpPortForm'
import { EnrichedInputWithEnrichedPorts } from './index'
import { rtmpDefaults } from './PortForm/IpPortForm/RtmpForm'
import {
  ApplianceSection,
  collectApplianceSectionEntries,
  isAppliance,
  isApplianceOrRegionSelectable,
  isCoreNode,
  makeApplianceSection,
} from '../../common/Interface/Base'
import { applianceList, getCoreNodesInput } from '../../common/Metadata'
import {
  DATE_FORMAT_LONG,
  getFormattedTransportStreamContent,
  getProductName,
  tsInfoServiceName,
} from 'common/api/v1/helpers'
import { getSettings } from '../../../redux/actions/settingsActions'
import { isFeatureOn } from '../../../utils/features'
import { EnrichedApplianceWithOwner, EnrichedInput, PaginatedRequestParams } from '../../../api/nm-types'
import { rerouteInput } from '../../../redux/actions/inputsActions'
import { ndiDefaults } from './PortForm/NdiForm'
import { InputTransformationSection } from '../DerivedInput/InputTransformationSection'
import { enqueueErrorSnackbar } from '../../../redux/actions/notificationActions'
import * as uuid from 'uuid'
import Tooltip from '../../common/Tooltip'
import { IS_CONNECT_IT } from '../../../env'
import { equals } from 'common/util'

const { inputApi } = Api

export const MAX_MAX_BITRATE_MBPS = 2147

export const initialInputLogicalPort = ({
  physicalPortId,
  port,
  enforcedMode,
  applianceAllocationId,
  numDistinctFailoverPriorities,
}: {
  physicalPortId?: string
  port?: PhysicalPort
  enforcedMode?: IpPortMode
  applianceAllocationId?: string
  numDistinctFailoverPriorities?: number
}) => ({
  ...udpDefaults(),
  ...rtpDefaults(),
  ...srtDefaults(numDistinctFailoverPriorities || 0),
  ...ristDefaults(),
  ...zixiDefaults(),
  ...rtmpDefaults(),
  ...generatorDefaults(),
  ...ndiDefaults(),
  _port: port,
  [CommonFields.mode]: enforcedMode ?? '',
  [CommonFields.physicalPort]: physicalPortId,
  [CommonFields.applianceAllocationId]: applianceAllocationId,
  // Provide an initial id to use as 'React.key'
  id: uuid.v4(),
})

const TsParsingMode = ({ disabled, thumbnailMode }: { disabled: boolean; thumbnailMode: ThumbnailMode }) => {
  return (
    <Select
      label="Broadcast standard for transport stream analysis"
      name="broadcastStandard"
      disabled={disabled}
      options={[
        { name: 'DVB', value: 'dvb' },
        { name: 'ATSC', value: 'atsc' },
        {
          name: 'No analysis',
          value: 'none',
          disabled: thumbnailMode === ThumbnailMode.edge,
        },
      ]}
      tooltip="Choose broadcast standard for mpeg-ts parsing or disable analysis by choosing 'No analysis'."
    />
  )
}

const UnhealthyAlarmLevel = (_props: object) => {
  return (
    <Select
      label="Alarm level when input is unhealthy"
      name="unhealthyAlarm"
      options={[
        { name: '(No alarm)', value: '' },
        { name: 'Critical', value: 'critical' },
        { name: 'Major', value: 'major' },
        { name: 'Minor', value: 'minor' },
        { name: 'Warning', value: 'warning' },
      ]}
      tooltip="Choose what type of alarm will be triggered when the input becomes unhealthy."
    />
  )
}

const VideoPreviewModeSelect = ({ disabled }: { disabled: boolean }) => {
  return (
    <Select
      label="Preview"
      disabled={disabled}
      name="videoPreviewMode"
      options={Object.values(VideoPreviewMode)}
      tooltip="'on demand' begins generating input preview when requested and is a more efficient alternative to 'always on', which constantly generates input preview and has a faster startup-time. No preview will be available if the input contains multiple video streams. Preview can only be enabled with 'core' thumbnail mode."
    />
  )
}

const thumbnailOptions = [
  { name: 'none', value: ThumbnailMode.none, hoverTooltip: 'thumbnail generation is disabled' },
  {
    name: 'core',
    value: ThumbnailMode.core,
    hoverTooltip: `thumbnails are generated on ${getProductName(ApplianceType.thumb)} appliances`,
  },
  {
    name: 'edge',
    value: ThumbnailMode.edge,
    hoverTooltip: 'thumbnails are generated on the ingress appliance',
  },
]

const ThumbnailModeSelect = () => {
  return <Select label="Thumbnail mode" name="thumbnailMode" options={thumbnailOptions} />
}

interface DeriveFromInputSelectProps {
  currentParentInput?: EnrichedInput
}

const DeriveFromInputSelect = ({ currentParentInput }: DeriveFromInputSelectProps) => {
  const form = useFormikContext<InputFormProps>()

  const api = useCallback(async (params: PaginatedRequestParams<any>) => {
    // Fetch all inputs except derived ones
    const response = await inputApi.getInputs.bind(inputApi)({ ...params, derived: false })

    // Remove current form input from response
    const itemsWithoutSelf = response.items.filter((item) => item.id !== form.values.id)
    return {
      items: itemsWithoutSelf,
      total: itemsWithoutSelf.length,
    }
  }, [])

  return (
    <Autocomplete<EnrichedInput>
      key={currentParentInput?.id}
      required={true}
      name="deriveFrom.parentInput"
      label="Input to derive from"
      formik={form}
      defaultOption={currentParentInput}
      tooltip="Search for the input to derive from. Other derived inputs are not shown here"
      api={api}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      optionComparator={(o1, o2) => o1.id === o2.id}
      data-test-id="deriveFrom.parent"
    />
  )
}

interface DerivedInputProps {
  currentParentInput?: EnrichedInput
}

const DerivedInput = ({ currentParentInput }: DerivedInputProps) => {
  const { values } = useFormikContext<InputFormProps>()

  return (
    <>
      <Checkbox name="_derived" label="Derive from input" />
      {!!values._derived && <DeriveFromInputSelect currentParentInput={currentParentInput} />}
    </>
  )
}

const CheckBoxUncheckedWhenDisabled = (props: CheckboxProps) => {
  // If disabled show a plain checkbox not connected to formik state
  const control = props.disabled ? <NonFormCheckbox disabled checked={false} /> : undefined
  return <Checkbox {...props} control={control} />
}

export interface InputFormProps extends EnrichedInputWithEnrichedPorts {
  _derived: boolean
  _selectedApplianceOrRegionId?: string
  _selectedApplianceOrRegion?: EnrichedApplianceWithOwner | Region
}

const InputForm = (form: FormikProps<InputFormProps>) => {
  const routes = useRoutes()
  // 'setFieldValue' only triggers a re-render if the field's reference/pointer is changed/replaced (i.e. not on modifications to the existing reference)
  const { values, setStatus, setFieldValue, dirty, isSubmitting, setSubmitting, initialValues } = form
  const user = useUser()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { devMode, settings } = useSelector(
    ({ settingsReducer }: GlobalState) => ({ devMode: settingsReducer.devMode, settings: settingsReducer.settings }),
    equals,
  )
  const isCopyingExistingInput = Boolean(useMatch(routes.inputsCopy.route))
  const isEditingExistingInput = !!values.id && !isCopyingExistingInput

  const [currentParentInput, setCurrentParentInput] = useState<EnrichedInput>()

  const formErrors = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.formErrors, equals)
  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])

  const isSaving = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.saving, shallowEqual)
  const isRerouting = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.rerouting, shallowEqual)
  useEffect(() => {
    if (isSaving === false) setSubmitting(false)
  }, [isSaving])

  useEffect(() => {
    dispatch(getSettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectedThumbnailMode = values.thumbnailMode
  const thumbnailModeSupportsPreview = selectedThumbnailMode === ThumbnailMode.core
  const isVideoPreviewSupported = !IS_CONNECT_IT
  const videoPreviewMode = values.videoPreviewMode
  useEffect(() => {
    function disablePreviewIfThumbnailingDisabled() {
      if (!thumbnailModeSupportsPreview && videoPreviewMode && videoPreviewMode !== VideoPreviewMode.off) {
        void setFieldValue('videoPreviewMode', VideoPreviewMode.off, false)
      }
    }
    disablePreviewIfThumbnailingDisabled()
  }, [thumbnailModeSupportsPreview, videoPreviewMode, setFieldValue])

  const selectedBroadcastStandard = values.broadcastStandard as 'none' | BroadcastStandard
  useEffect(() => {
    const shouldEnableBroadcastStandard =
      selectedThumbnailMode === ThumbnailMode.edge && selectedBroadcastStandard === 'none'
    if (shouldEnableBroadcastStandard) {
      void setFieldValue('broadcastStandard', settings?.defaultBroadcastStandard ?? 'dvb', false)
    }
  }, [selectedBroadcastStandard, selectedThumbnailMode, settings])

  // Fetch current select parent input and save in local state to avoid fetching it multiple times in child components
  useEffect(() => {
    const parentInputId = form.values.deriveFrom?.parentInput
    if (!parentInputId) {
      setCurrentParentInput(undefined)
      return
    }

    let cancelled = false
    inputApi
      .getInput(parentInputId)
      .then((input) => {
        if (cancelled) {
          // The useEffect cleanup has run and the component may have been unmounted
          return
        }

        setCurrentParentInput(input)
      })
      .catch((err) => {
        dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch input to derive from' }))
      })

    return () => {
      cancelled = true
    }
  }, [values.deriveFrom?.parentInput, setFieldValue])

  // Switch broadcast standard to mirror the current selected parent input
  useEffect(() => {
    if (currentParentInput === undefined) return
    setFieldValue('broadcastStandard', currentParentInput.broadcastStandard)
    setFieldValue('handoverMethod', currentParentInput.handoverMethod)
  }, [currentParentInput, setFieldValue])

  const coreNodes = getCoreNodesInput(values)

  const onRemoveInputAppliance = (key: string) => setFieldValue(key, undefined)
  const initialApplianceSections = collectApplianceSectionEntries(initialValues).map(([_k, value]) => value)
  const applianceSectionEntries = collectApplianceSectionEntries(values)
  const adminStatus = get(values, 'adminStatus') ? 1 : 0
  const applianceSections = applianceSectionEntries.map(([key, data], index) => {
    const isModeSelectionDisabled = data.ports.length > 1
    return (
      <ApplianceSection<EnrichedInputWithEnrichedPorts>
        title={`Input appliance #${index + 1}`}
        key={key}
        namePrefix={key}
        index={index}
        adminStatus={adminStatus}
        initialApplianceOrRegionId={
          initialApplianceSections[index]?.region?.id ?? initialApplianceSections[index]?.appliance?.id
        }
        isModeSelectionDisabled={isModeSelectionDisabled}
        onRemove={applianceSectionEntries.length > 1 ? onRemoveInputAppliance : undefined}
        inputId={isEditingExistingInput ? values.id : undefined}
        outputId={undefined}
        groupId={values.owner ?? user.group}
        isInputForm={true}
        enforcedPortMode={undefined}
        isEditingExistingEntity={isEditingExistingInput}
        isCopyingExistingEntity={isCopyingExistingInput}
        isApplianceOrRegionSelectable={(option) => isApplianceOrRegionSelectable(option, values, false)}
        onApplianceOrRegionSelected={(selected) => {
          if (!selected) {
            const [_, emptySection] = makeApplianceSection({ region: undefined, appliance: undefined })
            setFieldValue(key, emptySection)
          } else if (isAppliance(selected)) {
            const [_, applianceSection] = makeApplianceSection({ region: undefined, appliance: selected })
            setFieldValue(key, applianceSection)
          } else {
            const [_, regionalSection] = makeApplianceSection({ region: selected, appliance: undefined })
            setFieldValue(key, regionalSection)
          }
        }}
        {...form}
      />
    )
  })

  const transportStreamContent = getFormattedTransportStreamContent((values.tsInfo || [])[0])
  const contentFormat =
    transportStreamContent === 'MPTS'
      ? 'MPTS'
      : `${transportStreamContent} (${tsInfoServiceName((values.tsInfo || [])[0])})`

  const getRedundancyTooltip = () => {
    if (isCoreNode(values)) {
      return 'Not possible to enable redundancy when using a core appliance for the input.'
    }

    if (values._derived) {
      return 'Not possible to enable redundancy for a derived input.'
    }

    return "Redundancy enabled will route the input stream through an additional path in the input appliance's region(s)."
  }

  const redundancyCheckboxDisabled = isCoreNode(values) || values._derived
  const showTransformationSection = values._derived && currentParentInput !== undefined
  const showApplianceSections = !values._derived
  const canAddAdditionalInputAppliance = applianceSectionEntries.length === 1

  return (
    <Grid container data-test-input-id={`${values.id || ''}`}>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="input-form" translate="no" noValidate>
          <Paper className="outlined" title="Metadata" collapsible>
            <Paper>
              <TextInput name="name" label="Input name" required autoFocus />

              <Checkbox name="adminStatus" label="Enabled" />
              <CheckBoxUncheckedWhenDisabled
                name="_redundant"
                label="Redundant"
                disabled={redundancyCheckboxDisabled}
                tooltip={getRedundancyTooltip()}
              />
              <ThumbnailModeSelect />
              {isVideoPreviewSupported && <VideoPreviewModeSelect disabled={!thumbnailModeSupportsPreview} />}
              <TextInput
                noNegative
                name="maxBitrateMbps"
                label="Max bitrate (Mbps)"
                validators={{
                  number: {
                    lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
                    greaterThan: 0,
                    message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
                  },
                }}
                type="number"
                tooltip="Maximum bitrate allowed including retransmission. Packets exceeding the maximum bitrate will be dropped."
              />
              {devMode && <TextInput name="bufferSize" label="Buffer duration (ms)" required type="number" />}
              <TsParsingMode disabled={values._derived} thumbnailMode={selectedThumbnailMode} />
              {settings && isFeatureOn(ExpFeatures.ExtHealthAlarms, settings) && <UnhealthyAlarmLevel />}
              {settings && isFeatureOn(ExpFeatures.ExtDerivedInput, settings) && (
                <DerivedInput currentParentInput={currentParentInput} />
              )}
            </Paper>
            {isEditingExistingInput && (
              <Paper>
                <GridItem lg={12} xl={12}>
                  <DataSet
                    values={{
                      Id: values.id,
                      Created: format(new Date(values.createdAt), DATE_FORMAT_LONG),
                      Updated: format(new Date(values.updatedAt), DATE_FORMAT_LONG),
                      Access: values.canSubscribe ? 'Full Access' : 'Preview',
                      Owner: !!values._owner?.id && (
                        <Link
                          to={routes.groupsUpdate({ id: values._owner.id })}
                          underline="hover"
                          available={isEditableGroup(values._owner.id, user)}
                        >
                          {values._owner?.name}
                        </Link>
                      ),
                      Status: <AutoUpdatingInputHealthIndicator initialInput={values} inline />,
                      Format: contentFormat,
                      [`Input ${pluralizeWord(values.appliances?.length ?? 0, 'appliance')}`]: applianceList(
                        values.appliances ?? [],
                        user,
                      ),
                      [`Core video ${pluralizeWord(coreNodes.length, 'node')}`]: applianceList(coreNodes, user),
                      'Service Overview': (
                        <IconButton
                          aria-label="service overview button"
                          onClick={(e) => {
                            e.stopPropagation()
                            navigate(routes.service({ id: values.id }))
                          }}
                          sx={{ marginLeft: '-10px', marginTop: '-8px' }}
                        >
                          <Tooltip title={'Service Overview'} placement="top">
                            <Info />
                          </Tooltip>
                        </IconButton>
                      ),
                    }}
                  />
                </GridItem>
              </Paper>
            )}
          </Paper>
          {showTransformationSection && <InputTransformationSection currentParentInput={currentParentInput} />}
          {showApplianceSections && (
            <>
              {applianceSections}

              {canAddAdditionalInputAppliance && (
                <Button
                  sx={{ marginBottom: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const [key, value] = makeApplianceSection({ region: undefined, appliance: undefined })
                    setFieldValue(key, value)
                  }}
                >
                  Add input appliance
                </Button>
              )}
            </>
          )}

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.inputs()),
              },
              Save: { savingState: isSaving, primary: true, type: 'submit' },
            }}
            secondary={
              isEditingExistingInput
                ? {
                    'Use as template': {
                      onClick: () => navigate(routes.inputsCopy({ id: values.id })),
                    },
                    ...(devMode
                      ? {
                          Reroute: {
                            onClick: () => void dispatch(rerouteInput({ inputId: values.id })),
                            savingState: isRerouting,
                          },
                        }
                      : {}),
                  }
                : undefined
            }
          />
          <FormikErrorFocus />
        </Form>
      </Grid>
    </Grid>
  )
}

export default InputForm
