import { ApplianceFeatures, CoaxInputPort, CoaxPortMode, Input } from 'common/api/v1/types'
import { Select } from '../../../common/Form'
import { FormikProps } from 'formik'
import get from 'lodash/get'
import { useEffect, useMemo } from 'react'
import { RichOption } from '../../../common/Form/Select'
import { COAX_MODES } from '../../../../utils'

interface CoaxPortFormProps {
  namePrefix: string
  applianceFeatures: ApplianceFeatures
  form: FormikProps<Input>
}

const CoaxPortForm = ({ namePrefix, applianceFeatures, form }: CoaxPortFormProps) => {
  const { setFieldValue } = form
  const logicalPort: CoaxInputPort = get(form.values, namePrefix)

  const modeKey = `${namePrefix}.mode`
  const selectedMode = logicalPort.mode as CoaxPortMode | ''
  const modeOptions: RichOption[] = useMemo(() => {
    const coaxModes = applianceFeatures.input?.modes.filter((m) => COAX_MODES.includes(m.mode)) ?? []
    return coaxModes.map((m) => ({ name: m.prettyName ?? m.mode, value: m.mode }))
  }, [JSON.stringify(applianceFeatures.input?.modes)])

  useEffect(() => {
    const defaultMode = modeOptions.length === 1 ? modeOptions[0].value : ''
    const isValidMode = modeOptions.some((m) => m.value === selectedMode)
    if (!isValidMode && selectedMode !== defaultMode) {
      setFieldValue(modeKey, defaultMode, false)
    }
  }, [modeKey, setFieldValue, modeOptions, selectedMode])

  return (
    <Select
      label="Mode"
      name={modeKey}
      required
      newLine
      options={modeOptions}
      validators={{
        oneOf: { validValues: new Set(modeOptions.filter((o) => !o.disabled).map(({ value }) => value)) },
      }}
    />
  )
}

export default CoaxPortForm
