import Grid from '@mui/material/Grid'

import NumericalDataSet from '../../common/NumericalDataSet'
import Pendable from '../../common/Pendable'
import { getTransferredString } from 'common/api/v1/helpers'
import { UsageReport } from 'common/api/v1/types'

interface TotalProps {
  usage: UsageReport | undefined
  loading: boolean
}

const styles = {
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}

const Total = ({ usage, loading }: TotalProps) => {
  return (
    <Pendable pending={loading}>
      <Grid item sx={styles.wrapper}>
        {usage && (
          <NumericalDataSet
            values={[
              { title: 'Ingress transferred data', value: getTransferredString(usage.ingressTrafficGB) },
              { title: 'Egress transferred data', value: getTransferredString(usage.egressTrafficGB) },
            ]}
          />
        )}
      </Grid>
    </Pendable>
  )
}

export default Total
