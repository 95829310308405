import { ImgHTMLAttributes, SVGProps, useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Tooltip from './Tooltip'
import NotInterested from '@mui/icons-material/NotInterested'

import {
  Input,
  InputAdminStatus,
  OutputAdminStatus,
  OutputOperStatus,
  RistMetricType,
  ThumbnailMode,
  VideoPreviewMode,
} from 'common/api/v1/types'
import waitingThumb from 'url:../../img/thumb-waiting.svg'
import { registerOutputObserver, unregisterOutputObserver } from '../../redux/actions/outputsActions'
import { AppDispatch, GlobalState } from '../../store'
import { ThumbnailFetcher } from '../../thumbnail/ThumbnailFetcher'
import { isOutput } from '../../utils'
import { EnrichedOutput } from '../../api/nm-types'
import { ContentDialogContext } from './ContentDialog'
import { Preview } from './Preview'
import {
  ElementaryStreamTypeName,
  getAudioCodec,
  getInputPidsOnInputAppliance,
  getTransportStreamContentInfo,
  getVideoCodec,
  isMpts,
} from 'common/api/v1/helpers'
import { ChannelState } from 'common/rist'

enum ThumbnailTooltip {
  PreviewNotEnabled = 'Preview not enabled',
  PreviewNotSupported = 'Preview not supported',
  OutputDisabled = 'Output disabled',
  InputDisabled = 'Input disabled',
  NoVideo = 'No video',
  OutputHealthNotOk = 'Output health not OK',
  NoInput = 'No input',
  ThumbnailsDisabled = 'Thumbnails disabled',
  ClickToPreview = 'Click to preview',
  NotAuthorizedToPreview = 'Not authorized to preview',
}

const styles = {
  wrapper: {
    width: '100%',
    height: 0,
    position: 'relative',
    paddingTop: '56.25%',
  },
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  iconContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  common: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
  },
  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: `opacity 1s`,
  },
  videoModalButton: {
    width: '100%',
    height: '100%',
  },
}

interface ThumbnailProps {
  input?: Input
  channelId?: number
  outputId?: string
  linkToPlaylist?: boolean
  shouldHaveTopMargin?: boolean // HELP! What's a better way to do this styling?
}

const Thumbnail = ({
  input,
  outputId,
  linkToPlaylist = true,
  channelId,
  shouldHaveTopMargin = false,
}: ThumbnailProps) => {
  const imgProps = {
    style: { ...styles.common, ...styles.img },
    alt: 'stream preview thumbnail',
    draggable: false,
    'data-anchor': 'thumbnail',
  }
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (outputId) {
      dispatch(registerOutputObserver({ outputId }))
    }
    return () => {
      if (outputId) {
        dispatch(unregisterOutputObserver({ outputId }))
      }
    }
  }, [outputId])

  const selectorOutputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const selectorOutput = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.output, shallowEqual)
  const selectorOutputOrRecipientLists = useSelector(
    ({ outputsReducer }: GlobalState) => outputsReducer.outputsWithLists,
    shallowEqual,
  ).filter((o) => isOutput(o)) as EnrichedOutput[]
  const output = [...selectorOutputs, selectorOutput, ...selectorOutputOrRecipientLists].find((o) => o?.id == outputId)

  // Get active channelId for Output if Output is set
  if (output !== undefined) {
    channelId = output.metrics?.ristMetrics?.find(
      (metric) => metric.type === RistMetricType.channel && metric.state === ChannelState.activated,
    )?.channelId
  }

  // Otherwise get active channelId for the Input if no override is set
  if (channelId === undefined) {
    channelId = input?.metrics?.ristMetrics?.find(
      (metric) => metric.type === RistMetricType.channel && metric.state === ChannelState.activated,
    )?.channelId
  }

  const isInputDisabled = input?.adminStatus === InputAdminStatus.off
  const isOutputDisabled = output?.adminStatus == OutputAdminStatus.off
  const isTr101290Enabled = input?.tr101290Enabled !== false // TR101290 is most likely disabled due to the transport protocol being something else than MPEG-TS (meaning that no (Av)TsInfo is available).
  const isThumbnailGenerationDisabled = input?.thumbnailMode === ThumbnailMode.none
  const pids = getInputPidsOnInputAppliance(input, channelId)
  const { hasMpegTsAudio, hasMpegTsVideo } = getTransportStreamContentInfo(pids)

  const getComponent = () => {
    const tooltipIfPreviewable = (text: string) => (linkToPlaylist ? text : undefined)

    if (isOutputDisabled) {
      return <DisabledView tooltipText={tooltipIfPreviewable(ThumbnailTooltip.OutputDisabled)} />
    }

    if (isInputDisabled) {
      return <DisabledView tooltipText={tooltipIfPreviewable(ThumbnailTooltip.InputDisabled)} />
    }

    if (input === undefined || channelId === undefined) {
      // If an enabled Output doesn't have any Input configured
      return <NoInputImage />
    }

    // Show no thumbnail if TS-info is enabled and there are no streams in the info
    if (!hasMpegTsVideo && !hasMpegTsAudio && isTr101290Enabled) {
      return <NoThumbnailView tooltipText={tooltipIfPreviewable(ThumbnailTooltip.NoVideo)} imageProperties={imgProps} />
    }

    // If output is provided check that it's healthy otherwise show disabled
    if (output !== undefined) {
      const isOutputHealthy =
        output.health &&
        [
          OutputOperStatus.allOk,
          OutputOperStatus.notAcknowledged,
          OutputOperStatus.reducedRedundancy,
          OutputOperStatus.tr101290Priority1Error,
        ].includes(output.health.state)
      if (!isOutputHealthy) {
        return <DisabledView tooltipText={tooltipIfPreviewable(ThumbnailTooltip.OutputHealthNotOk)} />
      }
    }

    const previewMode = input.previewSettings?.mode || VideoPreviewMode.ondemand

    // Show audio only view if there are audio but no video. If both are false it may be that there are no TS-info, then fallback to video
    if (hasMpegTsAudio && !hasMpegTsVideo) {
      if (isThumbnailGenerationDisabled) {
        // Thumbnails are not relevant for audio-only streams. Therefore, show audio only image with tooltip 'preview not enabled'
        return (
          <AudioOnlyView
            tooltipText={tooltipIfPreviewable(ThumbnailTooltip.PreviewNotEnabled)}
            imageProperties={imgProps}
          />
        )
      }

      return (
        <ThumbnailAudioView
          input={input}
          linkToPlaylist={linkToPlaylist}
          previewMode={previewMode}
          imageProperties={imgProps}
        />
      )
    }

    if (isThumbnailGenerationDisabled) {
      return (
        <NoThumbnailView
          tooltipText={tooltipIfPreviewable(ThumbnailTooltip.ThumbnailsDisabled)}
          imageProperties={imgProps}
        />
      )
    }

    return (
      <ThumbnailVideoView
        input={input}
        channelId={channelId}
        previewMode={previewMode}
        linkToPlaylist={linkToPlaylist}
        imageProperties={imgProps}
      />
    )
  }

  return (
    <Box sx={{ ...styles.wrapper, marginTop: shouldHaveTopMargin ? '8px' : 0 }}>
      <Box sx={styles.container}>
        <Box sx={styles.common}>{getComponent()}</Box>
      </Box>
    </Box>
  )
}

const ThumbnailAudioView = ({
  input,
  previewMode,
  imageProperties,
  linkToPlaylist: linkToPlaylist = true,
}: {
  input: Input
  previewMode: VideoPreviewMode
  imageProperties: any
  linkToPlaylist: boolean
}) => {
  const inputId = input.id

  const audioCodec = getAudioCodec(input.tsInfo)
  const isMptsInput = isMpts(input.tsInfo)
  const supportedCodecs = [
    ElementaryStreamTypeName.adtsAac,
    ElementaryStreamTypeName.mpeg1,
    ElementaryStreamTypeName.mpeg2,
  ]
  const isPlayable = !isMptsInput && audioCodec !== null && supportedCodecs.includes(audioCodec)
  const isPreviewEnabled = previewMode === VideoPreviewMode.ondemand || previewMode === VideoPreviewMode.alwayson
  const authorizedToPreview = input.canSubscribe

  const {
    actions: { handleOpen },
  } = useContext(ContentDialogContext)

  const getImage = (tooltip?: ThumbnailTooltip) => {
    return <AudioOnlyView tooltipText={tooltip} imageProperties={imageProperties} />
  }

  if (!linkToPlaylist) {
    // Not supposed to be previewable/clickable by the context the component is used in
    return getImage()
  }

  if (!authorizedToPreview) {
    // EDGE-3409. If input has been shared from another group with preview only access
    // Preview only access means the the user doesn't have access to the stream data and therefore HLS preview isn't allowed
    return getImage(ThumbnailTooltip.NotAuthorizedToPreview)
  }

  if (!isPreviewEnabled) {
    return getImage(ThumbnailTooltip.PreviewNotEnabled)
  }

  if (!isPlayable) {
    return getImage(ThumbnailTooltip.PreviewNotSupported)
  }

  return (
    <Button
      sx={styles.videoModalButton}
      onClick={() => {
        handleOpen(
          <Preview inputId={inputId} isOnDemand={previewMode === VideoPreviewMode.ondemand} isAudioOnly={true} />,
          `Input: ${input.name}`,
        )
      }}
    >
      {getImage(ThumbnailTooltip.ClickToPreview)}
    </Button>
  )
}

const ThumbnailVideoView = ({
  input,
  channelId,
  previewMode,
  imageProperties,
  linkToPlaylist: linkToPlaylist = true,
}: {
  input: Input
  channelId: number
  previewMode: VideoPreviewMode
  imageProperties: any
  linkToPlaylist: boolean
}) => {
  const inputId = input.id
  const { thumbnailMode } = input
  const path = thumbnailMode === ThumbnailMode.core ? `thumb/${input.id}` : `thumbnailer/${channelId.toString()}`
  const thumbnails = useSelector(({ thumbnailReducer }: GlobalState) => thumbnailReducer.thumbnails)
  const cachedThumbnail = thumbnails[path]
  const thumbnail = ThumbnailFetcher.isThumbnailStale(cachedThumbnail) ? undefined : cachedThumbnail
  const FETCH_INTERVAL_MS = 3_000

  const codec = getVideoCodec(input.tsInfo)
  const isMptsInput = isMpts(input.tsInfo)
  const supportedCodecs = [ElementaryStreamTypeName.h264]
  const isPlayable = !isMptsInput && codec !== null && supportedCodecs.includes(codec)
  const isPreviewEnabled = previewMode === VideoPreviewMode.ondemand || previewMode === VideoPreviewMode.alwayson
  const authorizedToPreview = input.canSubscribe

  const {
    actions: { handleOpen },
  } = useContext(ContentDialogContext)

  useEffect(() => {
    ThumbnailFetcher.fetchThumbnailWithPath(path) // Fetch once initially
    const intervalId = setInterval(() => {
      ThumbnailFetcher.fetchThumbnailWithPath(path)
    }, FETCH_INTERVAL_MS)
    return () => clearInterval(intervalId)
  }, [input])

  const getImage = (tooltip?: ThumbnailTooltip) => {
    return (
      <TransitioningImage
        src={thumbnail?.url}
        errorSrc={waitingThumb}
        tooltipText={tooltip}
        imageProperties={imageProperties}
      />
    )
  }

  if (!linkToPlaylist) {
    // Not supposed to be previewable/clickable by the context the component is used in
    return getImage()
  }

  if (!authorizedToPreview) {
    // EDGE-3409. If input has been shared from another group with preview only access
    // Preview only access means the the user doesn't have access to the stream data and therefore HLS preview isn't allowed
    return getImage(ThumbnailTooltip.NotAuthorizedToPreview)
  }

  if (!isPreviewEnabled) {
    return getImage(ThumbnailTooltip.PreviewNotEnabled)
  }

  if (!isPlayable) {
    return getImage(ThumbnailTooltip.PreviewNotSupported)
  }

  return (
    <Button
      sx={styles.videoModalButton}
      onClick={() => {
        handleOpen(
          <Preview
            inputId={inputId}
            thumbnailUrl={thumbnail?.url}
            isOnDemand={previewMode === VideoPreviewMode.ondemand}
            isAudioOnly={false}
          />,
          `Input: ${input.name}`,
        )
      }}
    >
      {getImage(ThumbnailTooltip.ClickToPreview)}
    </Button>
  )
}

const TransitioningImage = ({
  src,
  errorSrc,
  tooltipText,
  imageProperties,
}: {
  src?: string
  errorSrc: string
  tooltipText?: string
  imageProperties: ImgHTMLAttributes<HTMLImageElement>
}) => {
  const [rearSrc, setRearSrc] = useState(src ?? errorSrc)
  const [frontSrc, setFrontSrc] = useState(src ?? errorSrc)
  const [frontOpacity, setFrontOpacity] = useState(0)

  const tooltipHidden = tooltipText === undefined
  const tooltipTitle = tooltipText ?? ''

  useEffect(() => {
    const nextFrontOpacity = frontOpacity === 0 ? 1 : 0
    const setSrcFn = nextFrontOpacity === 0 ? setRearSrc : setFrontSrc
    if (src) {
      setSrcFn(src)
    } else {
      setRearSrc(errorSrc)
      setFrontSrc(errorSrc)
    }
    setFrontOpacity(nextFrontOpacity)
  }, [src]) // Only re-run the effect if 'src' changes

  // Change front image opacity according to state
  const rearProps = { ...imageProperties }
  const frontProps = { ...imageProperties, style: { ...imageProperties.style, opacity: frontOpacity } }

  return (
    <Tooltip title={tooltipTitle} hidden={tooltipHidden} placement="top">
      <Box sx={styles.iconContainer}>
        <img data-testid="transitioning-image-rear" src={rearSrc} onError={() => setRearSrc(errorSrc)} {...rearProps} />
        <img
          data-testid="transitioning-image-front"
          src={frontSrc}
          onError={() => setFrontSrc(errorSrc)}
          {...frontProps}
        />
      </Box>
    </Tooltip>
  )
}

const NoInputImage = () => {
  const imgProps = {
    style: { ...styles.common, ...styles.img },
    alt: 'no input',
    draggable: false,
    'data-anchor': 'thumbnail',
  }
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.common}>
          <NoThumbnailView tooltipText={ThumbnailTooltip.NoInput} imageProperties={imgProps} />
        </Box>
      </Box>
    </Box>
  )
}

const DisabledView = ({ tooltipText }: { tooltipText?: string }) => {
  return (
    <Box sx={styles.iconContainer}>
      <Tooltip title={tooltipText} placement="top">
        <Icon>
          <NotInterested color="disabled" />
        </Icon>
      </Tooltip>
    </Box>
  )
}

const NoThumbnailView = ({ tooltipText, imageProperties }: { tooltipText?: string; imageProperties: any }) => {
  return <ThumbnailView text="NO THUMBNAIL" tooltipText={tooltipText} imageProperties={imageProperties} />
}

const AudioOnlyView = ({ tooltipText, imageProperties }: { tooltipText?: string; imageProperties: any }) => {
  return <ThumbnailView text="AUDIO ONLY" tooltipText={tooltipText} imageProperties={imageProperties} />
}

const ThumbnailView = ({
  text,
  tooltipText,
  imageProperties,
}: {
  text: string
  tooltipText?: string
  imageProperties: SVGProps<SVGSVGElement>
}) => {
  const tooltipHidden = tooltipText === undefined
  const tooltipTitle = tooltipText ?? ''
  return (
    <Box sx={styles.iconContainer}>
      <Tooltip title={tooltipTitle} placement="top" hidden={tooltipHidden}>
        <Icon>
          <svg
            width="534"
            height="300"
            viewBox="0 0 534 300"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...imageProperties}
          >
            <rect width="534" height="300" fill="white" />
            <rect width="534" height="300" fill="black" />
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              fill="white"
              fontFamily="Roboto"
              fontSize="50"
              fontWeight="bold"
              letterSpacing="7"
            >
              {text}
            </text>
            <path d="M91 181H442" stroke="white" strokeWidth="6" strokeDasharray="25 15" />
          </svg>
        </Icon>
      </Tooltip>
    </Box>
  )
}

export default Thumbnail
