import { onThumbnailsFetched } from '../redux/actions/thumbnailActions'
import store from '../store'

export type InputThumbnailMap = { [inputId: string]: FetchedThumbnail }

export interface FetchedThumbnail {
  serverFetchDate: Date
  serverLastModified: Date
  localFetchDate: Date
  url: string
}

const ongoingRequests = new Set<string>()

export class ThumbnailFetcher {
  public static fetchThumbnailWithPath = async (path: string) => {
    const thumbnails = store.getState().thumbnailReducer.thumbnails
    const cachedThumbnail = thumbnails[path]
    if (!ThumbnailFetcher.isThumbnailCacheDurationExceeded(cachedThumbnail)) {
      //console.log(`Returning cached thumbnail '${inputId}'`)
      return
    }
    const isFetchInProgress = ongoingRequests.has(path)
    if (isFetchInProgress) {
      //console.log(`Already fetching thumb for input '${inputId}'`)
      return
    }
    try {
      ongoingRequests.add(path)
      const fetchedThumbnail = await ThumbnailFetcher.doFetchThumbnailWithPath(path)
      store.dispatch(onThumbnailsFetched({ [path]: fetchedThumbnail }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed fetching thumb: `, error)
    } finally {
      ongoingRequests.delete(path)
    }
  }

  private static doFetchThumbnailWithPath = async (
    path: string, // thumbnailer/1234 or thumb/de305d54-75b4-431b-adb2-eb6b9e546014
  ): Promise<FetchedThumbnail> => {
    //console.log(`Fetching thumb for input '${inputId}'`)

    const response = await fetch(`${path}.jpg?${Math.floor(Date.now() / 1000)}`)
    if (!response.ok) {
      throw new Error(`Response not 200`)
    }
    const blob = await response.blob()
    const date = response.headers.get('Date')
    const lastModified = response.headers.get('Last-Modified')
    if (!date || !lastModified || !blob) {
      throw new Error(`Did not receive expected fields`)
    }
    return {
      serverFetchDate: new Date(date),
      serverLastModified: new Date(lastModified),
      localFetchDate: new Date(),
      url: URL.createObjectURL(blob),
    }
  }

  private static isThumbnailCacheDurationExceeded(thumbnail: FetchedThumbnail | undefined): boolean {
    if (!thumbnail) {
      return true
    }
    const MAX_CACHE_DURATION_MS = 5_000
    const wasFetchedTooLongAgo = new Date().getTime() - thumbnail.localFetchDate.getTime() > MAX_CACHE_DURATION_MS
    return wasFetchedTooLongAgo
  }

  public static isThumbnailStale(thumbnail: FetchedThumbnail | undefined): boolean {
    if (!thumbnail) {
      return true
    }
    // Reasoning behind MAX_THUMBNAIL_AGE_MS:
    // ristserver in R3.21.0 with thumbnailMode="edge" uses the received thumbnailConfig.intervalSeconds property of 5s
    // as a minimum/"not more frequently than" value and generates thumbnails as soon as it receives I-frames.
    // This means that the thumbnail interval is directly dependent on the GOP-size/I-frame interval of the incoming stream,
    // e.g. a GOP size of 600 (the max allowed value in a MatroxE4 encoder) and frame rate of 30 resulted in thumbnails
    // being generated every ~23s, so 15s was too short in this case.
    const MAX_THUMBNAIL_AGE_MS = 30_000
    const wasStaleImageServed =
      thumbnail.serverFetchDate.getTime() - thumbnail.serverLastModified.getTime() > MAX_THUMBNAIL_AGE_MS
    return wasStaleImageServed
  }
}
