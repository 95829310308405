import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import Warning from '@mui/icons-material/Warning'
import Stack from '@mui/material/Stack'

const styles = {
  title: {
    margin: (theme: Theme) => theme.spacing(2, 0),
  },
}

interface RemoveSecondaryRegionDialogProps {
  applianceName: string
}

export const RemoveSecondaryRegionDialog = ({ applianceName }: RemoveSecondaryRegionDialogProps) => {
  return (
    <>
      <Typography variant="h3" color="textPrimary" sx={styles.title}>
        You are about to remove secondary region for an appliance
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Warning />
        <Typography variant="h3">Warning!</Typography>
      </Stack>

      <Typography paragraph>
        Removing secondary region for appliance &apos;{applianceName}&apos; cause loss of multi-region redundancy for
        all redundant inputs on the appliance. The system will fall back to using single-region redundancy.
      </Typography>

      <Typography paragraph>Are you sure?</Typography>
    </>
  )
}
