import {
  Appliance,
  ApplianceConfiguration,
  ApplianceFilter,
  ApplianceInputInfo,
  ApplianceOutputInfo,
  ApplianceType,
  GeoAppliance,
  GeoApplianceFilter,
  Group,
  ListApplianceSortableField,
  ListResult,
  NdiSource,
  Pcap,
  SortOrder,
  UpdateAppliancePayload,
} from 'common/api/v1/types'
import { getApplianceOwnerId } from '../../utils'
import {
  AppliancesRequestParams,
  EnrichedAppliance,
  EnrichedApplianceWithOwner,
  PaginatedRequestParams,
  PhysicalPortInfoWithAppliance,
  singleSortQueryFromPaginatedRequestParams,
} from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'
import { Query } from 'common/query'

export interface IApplianceApi {
  getAppliance(id: Appliance['id']): Promise<EnrichedAppliance>
  listApplianceNdiSources(id: Appliance['id']): Promise<ListResult<NdiSource>>
  getAppliances(params: AppliancesRequestParams): Promise<ListResult<EnrichedApplianceWithOwner>>
  getBareAppliances(params: AppliancesRequestParams): Promise<ListResult<Appliance>>
  listAppliances(query?: Query<ApplianceFilter, SortOrder<ListApplianceSortableField>>): Promise<ListResult<Appliance>>
  listRegisteredApplianceTypes(): Promise<ListResult<ApplianceType>>
  getConfig(id: Appliance['id']): Promise<ApplianceConfiguration>
  getGeoAppliances(params: AppliancesRequestParams): Promise<ListResult<GeoAppliance>>
  removeAppliance(id: string): Promise<Pick<Appliance, 'id'>>
  restart(id: Appliance['id']): Promise<Appliance>
  recreateTunnels(id: Appliance['id']): Promise<Appliance>
  updateAppliance(id: string, values: UpdateAppliancePayload): Promise<Appliance>
  listApplianceInputs(id: Appliance['id'], params: PaginatedRequestParams): Promise<ListResult<ApplianceInputInfo>>
  listApplianceOutputs(id: Appliance['id'], params: PaginatedRequestParams): Promise<ListResult<ApplianceOutputInfo>>
  listAppliancePhysicalPorts(id: Appliance['id']): Promise<PhysicalPortInfoWithAppliance[]>
  deletePcap(applianceId: Appliance['id'], pcapId: string): Promise<Pcap[]>
  listAppliancePcaps(id: Appliance['id']): Promise<ListResult<Pcap>>
  clearCachedApplianceConfig(id: Appliance['id']): Promise<void>
}

export class AppliancesApi implements IApplianceApi {
  constructor(private readonly edgeClient: EdgeClient) {}
  listAppliancePcaps(id: string): Promise<ListResult<Pcap>> {
    return this.edgeClient.listAppliancePcaps(id)
  }

  listApplianceNdiSources(id: string): Promise<ListResult<NdiSource>> {
    return this.edgeClient.listApplianceNdiSources(id)
  }

  recreateTunnels(id: string): Promise<Appliance> {
    return this.edgeClient.recreateApplianceTunnels(id)
  }

  deletePcap(applianceId: string, pcapId: string): Promise<Pcap[]> {
    return this.edgeClient.deleteAppliancePcap(applianceId, pcapId) as any
  }

  removeAppliance(id: string): Promise<Pick<Appliance, 'id'>> {
    return this.edgeClient.deleteAppliance(id)
  }
  updateAppliance(id: Appliance['id'], values: UpdateAppliancePayload): Promise<Appliance> {
    return this.edgeClient.updateAppliance(id, values)
  }

  /**
   * Getting appliance populating it with full owner object and physical ports' owners as well
   * @param id - appliance Id
   */
  async getAppliance(id: string): Promise<EnrichedAppliance> {
    const appliance = await this.edgeClient.getAppliance(id)
    const applianceOwnerId = getApplianceOwnerId(appliance)
    const groupIds = Array.from(new Set([applianceOwnerId, ...appliance.physicalPorts.map((p) => p.owner)]))
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items
    const enrichedAppliance = {
      ...appliance,
      owner: groups.find(({ id }) => id === applianceOwnerId) as Group,
      _physicalPorts: appliance.physicalPorts.map((port) => ({
        ...port,
        _owner: groups.find(({ id }) => id === port.owner) as Group,
      })),
    }
    return enrichedAppliance
  }

  /**
   * Getting ListResult of appliances populating each item with owner group object
   * @param requestParams
   */
  async getAppliances(requestParams: AppliancesRequestParams): Promise<ListResult<EnrichedApplianceWithOwner>> {
    const applianceList = await this.getBareAppliances(requestParams)
    if (applianceList.items.length === 0) {
      return { ...applianceList, items: [] }
    }

    const groupIds = applianceList.items.reduce<Array<string>>((acc, a) => {
      const id = typeof a.owner === 'string' ? a.owner : a.owner.id
      if (acc.includes(id)) return acc
      return acc.concat(id)
    }, [])
    const groups = (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items
    const groupsMap = groups.reduce<{ [key: string]: Group }>((acc, item) => ({ ...acc, [item.id]: item }), {})

    return {
      ...applianceList,
      items: applianceList.items.map((a) => ({
        ...a,
        _owner: groupsMap[typeof a.owner === 'string' ? a.owner : a.owner.id],
      })),
    }
  }

  getBareAppliances({
    owner: groupId,
    groupName,
    filter: searchName,
    types,
    regions,
    ...params
  }: AppliancesRequestParams): Promise<ListResult<Appliance>> {
    const filter: ApplianceFilter = {
      group: groupId,
      groupName,
      searchName,
      regionNames: regions?.split(','),
      types: types?.split(',') as ApplianceType[] | undefined,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.listAppliances(query)
  }

  listAppliances(
    query?: Query<ApplianceFilter, SortOrder<ListApplianceSortableField>>,
  ): Promise<ListResult<Appliance>> {
    return this.edgeClient.listAppliances(query)
  }

  listRegisteredApplianceTypes(): Promise<ListResult<ApplianceType>> {
    return this.edgeClient.listApplianceRegisteredTypes()
  }

  /**
   * Returns appliances with coordination to show on map
   * @param searchName - term to search for
   * @param paginatedRequestParams
   */
  getGeoAppliances({
    filter: searchName,
    ...paginatedRequestParams
  }: PaginatedRequestParams): Promise<ListResult<GeoAppliance>> {
    const filter: GeoApplianceFilter = { searchName }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams })
    return this.edgeClient.listGeoAppliances(query)
  }

  /**
   * Command to restart appliance
   * @param id
   */
  restart(id: string): Promise<Appliance> {
    return this.edgeClient.restartAppliance(id, { timeoutMs: 180_000 })
  }

  /**
   * Returns appliance configuration for super user
   * @param id
   */
  getConfig(id: string): Promise<ApplianceConfiguration> {
    return this.edgeClient.getApplianceConfig(id, '')
  }

  listApplianceInputs(id: Appliance['id'], params: PaginatedRequestParams): Promise<ListResult<ApplianceInputInfo>> {
    const query = singleSortQueryFromPaginatedRequestParams({
      filter: undefined,
      paginatedRequestParams: params,
    })
    return this.edgeClient.listApplianceInputs(id, query)
  }

  listApplianceOutputs(id: Appliance['id'], params: PaginatedRequestParams): Promise<ListResult<ApplianceOutputInfo>> {
    const query = singleSortQueryFromPaginatedRequestParams({
      filter: undefined,
      paginatedRequestParams: params,
    })
    return this.edgeClient.listApplianceOutputs(id, query)
  }

  async listAppliancePhysicalPorts(id: Appliance['id']): Promise<PhysicalPortInfoWithAppliance[]> {
    const { appliance, physicalPorts } = await this.edgeClient.getAppliancePorts(id)
    return physicalPorts.map((p) => ({
      ...p,
      appliance,
    }))
  }

  clearCachedApplianceConfig(id: Appliance['id']) {
    return this.edgeClient.clearApplianceConfig(id)
  }
}
