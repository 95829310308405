import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { Appliance, ListResult, PhysicalPort, SharedPort } from 'common/api/v1/types'
import { EnrichedPhysicalPort, PortsRequestParams } from '../../api/nm-types'
import { ThunkApi } from '../../store'
import { withDefaultPagination } from '../../utils'

export const getPort = createAsyncThunk<EnrichedPhysicalPort, PhysicalPort['id'], ThunkApi>(
  'port/getPort',
  async (id, { extra: { api } }) => {
    return await api.portsApi.getPort(id)
  },
)

export const getPorts = createAsyncThunk<ListResult<EnrichedPhysicalPort>, Partial<PortsRequestParams>, ThunkApi>(
  'port/getPorts',
  async (params, { extra: { api } }) => {
    return await api.portsApi.getPorts(withDefaultPagination(withDefaultPagination(params)))
  },
)

export const updatePort = createAsyncThunk<
  Array<SharedPort>,
  { appliance: Appliance; sharedPorts: Array<SharedPort> },
  ThunkApi
>('port/updatePort', async ({ appliance, sharedPorts }, { dispatch, extra: { routes, api, navigate } }) => {
  try {
    const res = await api.portsApi.updatePort(appliance.id, sharedPorts)
    navigate()(routes.interfaces())
    dispatch(enqueueSuccessSnackbar(`Interface of ${appliance.name} updated`))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'update port' }))
    throw err
  }
})

export const clearPort = createAction('port/clearPort')
export const clearPorts = createAction('port/clearPorts')
