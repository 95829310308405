import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import VideoLibrary from '@mui/icons-material/VideoLibrary'
import { Theme } from '@mui/material/styles'

import { Input, OutputRecipientList, OutputOrRecipientList, Output } from 'common/api/v1/types'
import { inputOutputColor, isOutput } from '../../../../utils'
import { EnrichedOutput, OutputsRequestParams } from '../../../../api/nm-types'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import Thumbnail from '../../../common/Thumbnail'
import { Api } from '../../../../store'
import { Table } from '../../../common/Table'
import { AutoUpdatingOutputHealthIndicator as HealthIndicator } from '../../../common/Indicator'

const styles = {
  row: {
    cursor: 'pointer',
  },
  thumbnail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: (theme: Theme) => theme.spacing(1),
    minWidth: '80px',
    maxWidth: '120px',
    minHeight: '50px',
  },
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
  owner: {
    display: 'inline-block',
    width: '140px',
  },
  name: {
    fontWeight: 'bold',
  },
}

const outputApi = Api.outputApi

interface AvailableGroupsProps {
  input: Input
  onSend: (output: OutputOrRecipientList) => void
  onCancel: (outputId: string, isList?: boolean) => void
  changes: { [key: string]: Output }
  listChanges: Array<OutputRecipientList['id']>
}
const AvailableOutputs = ({ input, onSend, onCancel, changes, listChanges }: AvailableGroupsProps) => {
  const [hideFilter, setHideFilter] = useState(true)
  return (
    <div data-test-id="outputs-container">
      <Typography variant="h2">Add recipients</Typography>
      <PaginatedList<OutputsRequestParams, EnrichedOutput | (OutputRecipientList & { _group?: { name: string } })>
        api={outputApi.getOutputsWithLists.bind(outputApi)}
        emptyMessage="no available outputs"
        hideSearch={hideFilter}
        notFoundMessage="no matching outputs"
        onListChange={({ filter, total = 0 }) => setHideFilter(!filter && total < 10)}
        otherParams={{ notInput: input.id }}
        searchPlaceholder="Find specific output…"
        List={({ list }) => (
          <Table
            data={list}
            isSmall
            hasBorders
            noHeader
            rowProps={(item) => ({
              onClick: () => (isOutput(item) ? !(item.id in changes) : !listChanges.includes(item.id)) && onSend(item),
              sx: styles.row,
            })}
            config={[
              {
                getValue: (item) => (
                  <Box sx={styles.thumbnail}>
                    {isOutput(item) ? (
                      !!item._input && <Thumbnail input={item._input} outputId={item.id} linkToPlaylist={false} />
                    ) : (
                      <Icon>
                        <VideoLibrary />
                      </Icon>
                    )}
                  </Box>
                ),
              },
              {
                getValue: (item) =>
                  isOutput(item) ? (
                    <>
                      <Typography
                        component="div"
                        sx={styles.name}
                        data-test-id="output-name"
                        {...inputOutputColor(item)}
                      >
                        {item.name}
                      </Typography>
                      {!!item._input && (
                        <Typography color="textSecondary" variant="body2" component="div">
                          {item._input.name}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography data-test-id="output-name">{item.name}</Typography>
                  ),
              },
              {
                getValue: (item) =>
                  isOutput(item) ? (
                    <HealthIndicator outputId={item.id} />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      output list
                    </Typography>
                  ),
              },
              {
                getValue: (item) =>
                  isOutput(item) ? (
                    <Box sx={styles.owner}>
                      {item.id in changes ? (
                        <Button sx={styles.button} onClick={() => onCancel(item.id)} variant="outlined" size="small">
                          Cancel sending
                        </Button>
                      ) : (
                        <Typography color="textSecondary" variant="body2">
                          {item._group?.name}
                        </Typography>
                      )}
                    </Box>
                  ) : listChanges.includes(item.id) ? (
                    <Button sx={styles.button} onClick={() => onCancel(item.id, true)} variant="outlined" size="small">
                      Cancel sending
                    </Button>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      {item._group?.name}
                    </Typography>
                  ),
                props: {
                  align: 'right',
                  noTypography: true,
                },
              },
            ]}
          />
        )}
      />
    </div>
  )
}

export default AvailableOutputs
