import { configureStore, isPlain } from '@reduxjs/toolkit'
import type { NavigateFunction } from 'react-router-dom'

import rootReducer from './redux/reducers'
import { Api as ProdApi } from './api/Api'
import { loadState } from './localstorage'
import { BrowserEdgeClient } from './api/network'
import { makeRoutes, Routes } from './utils/routes'
import { createContext, useContext } from 'react'

const edgeClient = new BrowserEdgeClient({ baseUrl: '' })
export const Api = new ProdApi(edgeClient)
const persistedState = loadState()
/**
 * Container to have access to Navigation API managed by react-router-dom from redux epics
 */
const navigateContainer = {
  navigate: {} as NavigateFunction,
  setNavigate(navigate: NavigateFunction) {
    Object.assign(this, { navigate })
  },
}

const getCachedUrlParamsFn = () => store.getState().urlParamReducer.cachedUrlParams
const routes = makeRoutes(getCachedUrlParamsFn)

const dependencies = {
  api: Api,
  routes,
  setNavigate(n: NavigateFunction) {
    navigateContainer.setNavigate(n)
  },
  navigate: () => navigateContainer.navigate,
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  devTools: true, // process.env.NODE_ENV !== 'production', // It's ok to always have it enabled
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { isSerializable: (value: unknown) => value instanceof Date || isPlain(value) }, // Allow Dates in the store since they are kind of serializable
      thunk: {
        extraArgument: dependencies,
      },
    }),
})

export const storeWithRoutes = {
  store,
  routes,
}

export const RouteContext = createContext<{
  routes: Routes
}>({ routes })

export const useRoutes = () => {
  const { routes } = useContext(RouteContext)
  return routes
}

export type AppDispatch = typeof store.dispatch
export type GlobalState = ReturnType<typeof store.getState>
export type ThunkApi = { dispatch: AppDispatch; state: GlobalState; extra: typeof dependencies }

export default store
