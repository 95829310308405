import { stringValueFromObject } from '../../utils/error'

export interface ErrorInfo {
  errorCode: string
  fatal: boolean
  text: string
  details: any
  origin: any
}

export class ApplicationException {
  constructor(public errorInfo: ErrorInfo) {}
}

export function makeApplicationException(error: object) {
  return error instanceof ApplicationException
    ? error
    : new ApplicationException({
        fatal: true,
        errorCode: 'http_get_general_error',
        text: stringValueFromObject('title', error) ?? stringValueFromObject('name', error) ?? 'Unable to fetch',
        details:
          stringValueFromObject('detail', error) ?? stringValueFromObject('message', error) ?? 'Http Request Failed',
        origin: (error as any).response,
      })
}
