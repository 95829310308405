import { Fragment, isValidElement } from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const Container = styled('dl')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'repeat(auto-fill, auto)',
  gridRowGap: theme.spacing(1),
  gridColumnGap: theme.spacing(3),
}))

export interface DataSetProps {
  values: {
    [key: string]: string | number | undefined | React.ReactNode
  }
}

/**
 * Common data set display
 * @param values - map of key=title, value=value to show
 */
const DataSet = ({ values }: DataSetProps) => {
  return (
    <Container>
      {Object.entries(values).map(([key, value]) => (
        <Fragment key={key}>
          <dt>
            <Typography sx={{ fontWeight: 'bold' }}>{key}</Typography>
          </dt>
          <dd data-value-for={key}>
            {isValidElement(value) ? value : <Typography component="span">{value}</Typography>}
          </dd>
        </Fragment>
      ))}
    </Container>
  )
}

export default DataSet
