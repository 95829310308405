// Might want to include owner when we display appliance name,
// but since this is currently only used for linking a core node
// I'm leaving it out
import { Appliance, ApplianceType, Input, LimitedAppliance, Output, User } from 'common/api/v1/types'
import { Link } from './Link'
import { hasAccessToAppliance } from '../../utils'

import { useRoutes } from '../../store'

const ApplianceLink = ({ appliance }: { appliance: Pick<Appliance, 'id' | 'name'> }) => {
  const routes = useRoutes()
  return (
    <Link to={routes.appliancesUpdate({ id: appliance.id })} underline="hover">
      {appliance.name}
    </Link>
  )
}

export function getCoreNodesInput(values: Input): (Appliance | LimitedAppliance)[] {
  if (values.appliances && values.appliances[0]?.type === ApplianceType.core) {
    return values.appliances
  }

  if (values.downstreamAppliances.length) {
    return values.downstreamAppliances
  }

  return []
}

export function getCoreNodesOutput(values: Output): (Appliance | LimitedAppliance)[] {
  if (values.appliances && values.appliances[0].type === ApplianceType.core) {
    return values.appliances
  }

  if (values.upstreamAppliances.length) {
    return values.upstreamAppliances.filter((a) => a.type === ApplianceType.core)
  }

  return []
}

export function applianceList(appliances: (Appliance | LimitedAppliance)[], user: User) {
  if (appliances.length === 0) {
    return ''
  }
  return (
    <>
      {appliances
        .map((appliance, i, arr) => {
          const components: (JSX.Element | string)[] = [
            hasAccessToAppliance(appliance, user) ? (
              <ApplianceLink key={appliance.id} appliance={appliance} />
            ) : (
              appliance.name
            ),
          ]
          if (i !== arr.length - 1) {
            components.push(', ')
          }
          return components
        })
        .flat()}
    </>
  )
}
