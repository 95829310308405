import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/fp/get'

import { OutputRecipientList } from 'common/api/v1/types'
import {
  clearGroupList,
  createGroupList,
  getGroupList,
  getGroupLists,
  removeGroupList,
  updateGroupList,
} from '../actions/groupListsActions'

import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import { createLoadingReducer } from './shared'

interface State {
  formErrors?: Array<{ name: string; reason: string }>
  loading: boolean
  groupList?: OutputRecipientList
  groupLists: Array<OutputRecipientList>
  saving?: boolean
  total: number
}
export const initialStateGroupLists: State = {
  groupLists: [],
  loading: false,
  total: 0,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(removeGroupList, getGroupLists)

const groupListsSlice = createSlice({
  name: 'groupLists',
  initialState: initialStateGroupLists,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getGroupLists.fulfilled,
        (state, { payload: { items: groupLists, total } }): State => ({
          ...state,
          groupLists,
          total,
        }),
      )
      .addCase(getGroupList.fulfilled, (state, { payload: groupList }): State => ({ ...state, groupList }))
      .addCase(clearGroupList, (state): State => ({ ...state, groupList: undefined, formErrors: undefined }))
      .addCase(logoutUser.fulfilled, (): State => initialStateGroupLists)
      .addMatcher(
        isOneOf([updateGroupList.pending, createGroupList.pending]),
        (state): State => ({ ...state, saving: true }),
      )
      .addMatcher(
        isOneOf([createGroupList.fulfilled, updateGroupList.fulfilled]),
        (state): State => ({
          ...state,
          saving: undefined,
          formErrors: undefined,
        }),
      )
      .addMatcher(
        isOneOf([updateGroupList.rejected, createGroupList.rejected]),
        (state, { payload }): State => ({
          ...state,
          saving: false,
          formErrors: get('errorInfo.origin.data.detail', payload),
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default groupListsSlice.reducer
