import { FormikProps } from 'formik'
import Button from '@mui/material/Button'
import { Input, PortType, VideonPortMode } from 'common/api/v1/types'
import IpPortForm from './IpPortForm'
import CoaxPortForm from './CoaxPortForm'
import { GridItem } from '../../../common/Form'
import VideonPortForm from './VideonPortForm'
import NdiForm from './NdiForm'
import EncoderSettings from '../EncoderSettings/EncoderSettings'
import { PhysicalPortInfoWithAppliance } from '../../../../api/nm-types'

interface IPortFormProps {
  form: FormikProps<Input>
  index: number
  physicalPort: PhysicalPortInfoWithAppliance
  isModeDisabled: boolean
  namePrefix: string
  onRemoveLogicalPortRequested?: (index: number) => void
  onAddLogicalPortRequested: () => void
}

export const PortForm = ({
  physicalPort,
  isModeDisabled,
  namePrefix,
  form,
  index,
  onRemoveLogicalPortRequested: onRemove,
  onAddLogicalPortRequested,
}: IPortFormProps) => {
  return (
    <>
      {physicalPort.portType === PortType.ip && (
        <IpPortForm
          index={index}
          namePrefix={namePrefix}
          form={form}
          addresses={physicalPort.addresses}
          applianceType={physicalPort.appliance.type}
          applianceVersion={physicalPort.appliance.version}
          applianceFeatures={physicalPort.appliance.features}
          occupiedPorts={physicalPort.occupiedPorts}
          isModeDisabled={isModeDisabled}
          onAddLogicalPortRequested={onAddLogicalPortRequested}
        />
      )}

      {physicalPort.portType === PortType.coax && (
        <CoaxPortForm namePrefix={namePrefix} form={form} applianceFeatures={physicalPort.appliance.features} />
      )}

      {physicalPort.portType === PortType.videon && (
        <VideonPortForm
          namePrefix={namePrefix}
          applianceFeatures={physicalPort.appliance.features}
          limitToMode={
            physicalPort.occupiedPorts.find((p) => p.portMode in VideonPortMode && !!p.inputId)
              ?.portMode as VideonPortMode
          }
        />
      )}

      {physicalPort.portType === PortType.ndi && (
        <NdiForm form={form} namePrefix={namePrefix} applianceId={physicalPort.appliance.id} />
      )}

      {onRemove && (
        <GridItem newLine>
          <Button style={{ marginTop: 12 }} variant="outlined" color="primary" onClick={() => onRemove(index)}>
            Remove interface
          </Button>
        </GridItem>
      )}

      <EncoderSettings form={form} namePrefix={namePrefix} applianceFeatures={physicalPort.appliance.features} />
    </>
  )
}
