import get from 'lodash/get'
import { ApplianceFeatures, VideonInputPort, VideonPortMode } from 'common/api/v1/types'
import { useFormikContext } from 'formik'

import { Select } from '../../../common/Form'
import { RichOption } from '../../../common/Form/Select'
import { useEffect, useMemo } from 'react'

interface VideonPortFormProps {
  namePrefix: string
  applianceFeatures: ApplianceFeatures
  limitToMode?: VideonPortMode
}

const VideonPortForm = ({ namePrefix, limitToMode, applianceFeatures }: VideonPortFormProps) => {
  const { setFieldValue, values } = useFormikContext()

  const logicalPort: VideonInputPort = get(values, namePrefix)

  const modeKey = `${namePrefix}.mode`
  const selectedMode = logicalPort.mode as VideonPortMode | ''
  const modeOptions: RichOption[] = useMemo(() => {
    const videonModes = applianceFeatures.input?.modes.filter((m) => m.mode in VideonPortMode) ?? []
    return videonModes.map((m) => {
      const disabled = Boolean(limitToMode && limitToMode != m.mode)
      return {
        name: m.prettyName ?? m.mode,
        value: m.mode,
        disabled,
        tooltip: disabled ? 'All active inputs on a videon appliance must have the same mode' : undefined,
      }
    })
  }, [JSON.stringify(applianceFeatures.input?.modes), limitToMode])

  useEffect(() => {
    const defaultMode = modeOptions.length === 1 ? modeOptions[0].value : ''
    const isValidMode = modeOptions.some((m) => m.value === selectedMode)
    if (!isValidMode && selectedMode !== defaultMode) {
      setFieldValue(modeKey, defaultMode, false)
    }
  }, [modeKey, setFieldValue, modeOptions, selectedMode])

  return (
    <Select
      label="Mode"
      name={modeKey}
      required
      newLine
      options={modeOptions}
      validators={{
        oneOf: { validValues: new Set(modeOptions.filter((o) => !o.disabled).map((o) => o.value)) },
      }}
    />
  )
}

export default VideonPortForm
