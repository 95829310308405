import React from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

import { GridItem, Paper } from '../common/Form'
import DataSet from '../common/DataSet'
import { ExternalLink } from '../common/Link'
import { Service } from 'common/api/v1/types'
import { Product } from './Product'

export interface MetaProps {
  service: Service
}

const VolumeCapacityList = ({ currentVolumes }: Pick<Service, 'currentVolumes'>) => {
  if (!currentVolumes) return 'N/A'

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
      {currentVolumes.map((volume) => (
        <Chip key={volume.name} label={`${volume.name}: ${volume.currentVolumeCapacity}`} size="small" />
      ))}
    </Box>
  )
}

const RetentionPeriodList = ({ retentionPeriods }: Required<Pick<Service, 'retentionPeriods'>>) => {
  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
      {retentionPeriods.map((period) => (
        <Chip key={period.name} label={`${period.name}: ${period.current} day(s)`} size="small" />
      ))}
    </Box>
  )
}

export const Meta: React.FunctionComponent<MetaProps> = ({ service }) => {
  const logs = {
    Logs: (
      <ExternalLink underline="always" href={service.filterUrl}>
        Logs
      </ExternalLink>
    ),
  }

  const meta = {
    Purpose: service.purpose,
    Product: <Product product={service.product} />,
    ...logs,
    'Current volume capacity': <VolumeCapacityList currentVolumes={service.currentVolumes} />,
    ...(service.retentionPeriods
      ? { 'Retention period': <RetentionPeriodList retentionPeriods={service.retentionPeriods} /> }
      : {}),
  }

  return (
    <Paper title="Metadata" collapsible actionsPane={[]}>
      <GridItem lg={12} xl={12}>
        <DataSet values={meta} />
      </GridItem>
    </Paper>
  )
}
