import { useState } from 'react'
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Delete from '@mui/icons-material/Delete'
import MoreVert from '@mui/icons-material/MoreVert'

import type { AlarmWithImpact } from 'common/api/v1/types'
import { styles } from '../../../Common'
import { clearAlarm } from '../../../redux/actions/alarmsActions'
import type { AppDispatch } from '../../../store'

interface ActionMenuProps {
  alarm: AlarmWithImpact
}

export const ActionMenu = ({ alarm }: ActionMenuProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu open={open} keepMounted anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          sx={(theme) => ({ ...styles.error(theme), '&:hover': styles.error })}
          onClick={(e) => {
            handleItemClick(e)
            dispatch(clearAlarm({ alarmId: alarm.alarmId }))
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Clear" />
        </MenuItem>
      </Menu>
    </>
  )
}
