import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'

import Inputs from './Inputs'
import Outputs from './Outputs'
import { PageTitle } from '../../common/PageTitle'

const styles = {
  container: {
    height: '100%',
    display: 'flex',
  },
  inputs: (theme: Theme) => ({
    width: '70%',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  }),
  outputs: (theme: Theme) => ({
    width: '30%',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  }),
}

export const List = () => {
  return (
    <>
      <PageTitle title="Stream Manager" />
      <Box sx={styles.container}>
        <Box sx={styles.inputs}>
          <Inputs />
        </Box>
        <Box sx={styles.outputs}>
          <Outputs />
        </Box>
      </Box>
    </>
  )
}
