import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { format, formatDistanceToNow } from 'date-fns'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '../../common/Tooltip'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import { AlarmCause, AlarmSeverityLevels, ListAlarmSortableField, VaAlarmOwnSeverityLevels } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import Pendable from '../../common/Pendable'
import { Table } from '../../common/Table'
import { Link } from '../../common/Link'
import { useAlarmsSelector, useInterval, usePageParams, usePageParamsFilteredSelector } from '../../../utils'
import { AlarmsRequestParams } from '../../../api/nm-types'
import { getAlarms, readAlarmsInBackground } from '../../../redux/actions/alarmsActions'
import { DATE_FORMAT_LONG } from 'common/api/v1/helpers'
import { useRoutes } from '../../../store'
import { ActionMenu } from './ActionMenu'
import type { EnrichedAlarm } from '../../../api/nm-types'

const styles = {
  container: {
    width: '100%',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
  noValue: {
    padding: (theme: Theme) => theme.spacing(2),
  },
}

const BADGE_SIZE = 1.4
const tabStyles = {
  tab: {
    minWidth: 'auto',
    padding: (theme: Theme) => theme.spacing(1),
  },
  full: {
    opacity: 1,
  },
  badge: {
    display: 'inline-block',
    padding: `0 ${BADGE_SIZE / 3}rem`,
    margin: (theme: Theme) => theme.spacing(0, 1),
    minWidth: `${BADGE_SIZE}rem`,
    height: `${BADGE_SIZE}rem`,
    lineHeight: BADGE_SIZE * 1.2,
    borderRadius: `${BADGE_SIZE / 2}rem`,
    color: (theme: Theme) => theme.palette.grey[900],
  },
}

const badgeColor = {
  [AlarmSeverityLevels.critical]: (theme: Theme) => theme.palette.error.main,
  [AlarmSeverityLevels.major]: (theme: Theme) => theme.palette.warning.main,
  [AlarmSeverityLevels.minor]: (theme: Theme) => theme.palette.success.dark,
  [AlarmSeverityLevels.warning]: (theme: Theme) => theme.palette.primary.light,
}

interface TabLabelProps {
  alarms: Record<string, EnrichedAlarm[]>
  type: AlarmSeverityLevels
  isCriticalAmount: boolean
}

const getTabProps = ({ alarms, type, isCriticalAmount }: TabLabelProps) => {
  const amount = alarms[type].length
  return {
    label: (
      <div>
        {(amount || isCriticalAmount) && (
          <Box sx={{ ...tabStyles.badge, background: badgeColor[type] }}>{isCriticalAmount ? '!!!' : amount}</Box>
        )}
        <span>{type}</span>
      </div>
    ),
    sx: amount ? { ...tabStyles.tab, ...tabStyles.full } : tabStyles.tab,
    value: type,
  }
}

const getAlarmTime = (alarm: EnrichedAlarm) => new Date(alarm.time)

interface AlarmsProps {
  showTexts?: boolean
  size?: 'medium' | 'small'
  showClearButton?: boolean
}

const MOST_SEVERE_LEVEL = AlarmSeverityLevels.critical

const Alarms = ({ showTexts, size = 'medium', showClearButton }: AlarmsProps) => {
  const routes = useRoutes()
  const levels = Object.keys(AlarmSeverityLevels) as AlarmSeverityLevels[]
  const { alarms, loading, mostSevereLevel } = usePageParamsFilteredSelector((params: AlarmsRequestParams) => {
    // Delete alarmTab since it isn't sent to the backend and causes unnecessary requests on each tab change otherwise
    const alarmsSelector = useAlarmsSelector({ ...params, alarmTab: undefined } as AlarmsRequestParams) // eslint-disable-line react-hooks/rules-of-hooks
    const alarms = alarmsSelector.alarms.reduce(
      (acc, item) => {
        const severity = item.alarmSeverity
        if (severity === VaAlarmOwnSeverityLevels.cleared) {
          return acc
        }
        return { ...acc, [severity]: [...acc[severity], item] }
      },
      {
        [AlarmSeverityLevels.critical]: [],
        [AlarmSeverityLevels.major]: [],
        [AlarmSeverityLevels.minor]: [],
        [AlarmSeverityLevels.warning]: [],
      },
    )
    const mostSevereLevel = levels.find((level) => alarms[level].length > 0) ?? MOST_SEVERE_LEVEL
    return { alarms, loading: alarmsSelector.loading, mostSevereLevel }
  })
  const { isCriticalAmount } = useSelector(({ alarmsReducer }: GlobalState) => alarmsReducer, shallowEqual)
  const [tab, setTab] = useState<AlarmSeverityLevels | undefined>(undefined)
  const dispatch = useDispatch<AppDispatch>()
  const [params, setPageParams] = usePageParams<{ alarmTab: AlarmSeverityLevels | undefined }>()

  useEffect(() => {
    dispatch(readAlarmsInBackground({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPageParams({ alarmTab: tab })
  }, [tab])

  useEffect(() => {
    setTab(mostSevereLevel)
  }, [mostSevereLevel])

  useInterval(async () => {
    dispatch(getAlarms({ ...params, silent: true }))
  }, 10_000)

  const effectiveTab = tab ?? MOST_SEVERE_LEVEL

  return (
    <>
      <Tabs
        value={effectiveTab}
        variant="fullWidth"
        onChange={(_, val) => {
          setTab(val)
        }}
        sx={styles.container}
        textColor="inherit"
      >
        {levels.map((level) => (
          <Tab
            key={`tab-${level}`}
            data-test-id={`tab-${level}`}
            {...getTabProps({ alarms, isCriticalAmount, type: level })}
          />
        ))}
      </Tabs>
      <Pendable pending={loading}>
        <Table<EnrichedAlarm>
          id="alarms"
          emptyMessageComponent={
            <Grid container justifyContent="space-around">
              <Grid item>
                <Typography sx={styles.noValue} color="textSecondary">
                  {isCriticalAmount
                    ? 'The amount of alarms is too large, only recent ones are fetched'
                    : `No ${effectiveTab} alarms.`}
                </Typography>
              </Grid>
            </Grid>
          }
          data={alarms[effectiveTab]}
          getCustomEntityId={(alarm) => `${alarm.alarmId}-${alarm.applianceId}`}
          isSmall={size === 'small'}
          config={[
            {
              title: 'Time ago',
              getValue: (alarm) => (
                <Tooltip
                  data-test-id="time-raw"
                  title={format(new Date(getAlarmTime(alarm)), DATE_FORMAT_LONG)}
                  placement="top"
                >
                  <Typography color="textPrimary" variant="body2">
                    {formatDistanceToNow(getAlarmTime(alarm))}
                  </Typography>
                </Tooltip>
              ),
              headerProps: { sx: { whiteSpace: 'nowrap' } },
              sorting: { byParameter: ListAlarmSortableField.time },
            },
            {
              title: 'Appliance',
              getValue: ({ applianceId, applianceName }) => {
                if (applianceId) {
                  return (
                    <Link underline="hover" to={routes.appliancesUpdate({ id: applianceId })}>
                      {applianceName}
                    </Link>
                  )
                }
              },
              sorting: { byParameter: ListAlarmSortableField.applianceName },
            },
            {
              title: 'Alarm',
              getValue: ({ alarmCause }) => alarmCause,
              sorting: { byParameter: ListAlarmSortableField.alarmCause },
            },
            showTexts
              ? {
                  title: 'Message',
                  getValue: ({ text, object, objectName, repeatCount }) => {
                    const isVALicenseAlarm = !!object?.startsWith('.license')
                    const textDetails = isVALicenseAlarm ? ` - ${objectName}` : ''
                    const repeatText = repeatCount > 0 ? ` (repeated ${repeatCount} times)` : ''
                    return `${text}${textDetails}${repeatText}`
                  },
                  sorting: { byParameter: ListAlarmSortableField.text },
                }
              : null,
            {
              title: 'Entity',
              getValue: ({ _input, _output, applianceId, applianceName, alarmCause, objectName }) => {
                if (_input) {
                  return (
                    <Link underline="hover" to={routes.service({ id: _input.id })}>
                      {`input "${_input.name}"`}
                    </Link>
                  )
                }
                if (_output) {
                  return (
                    <Link
                      underline="hover"
                      to={
                        _output.input
                          ? routes.service({ id: _output.input, outputId: _output.id })
                          : routes.outputsUpdate({ id: _output.id })
                      }
                    >
                      {`output "${_output.name}"`}
                    </Link>
                  )
                }
                if (applianceId) {
                  return (
                    <Link underline="hover" to={routes.appliancesUpdate({ id: applianceId })}>
                      {`appliance "${applianceName}"`}
                    </Link>
                  )
                }
                if (alarmCause === AlarmCause.BACKUP_JOB_FAILED) {
                  return `job "${objectName}"`
                }
                return ''
              },
            },
            {
              title: '',
              getValue: (row) => (showClearButton ? <ActionMenu alarm={row} /> : null),
            },
          ]}
        />
      </Pendable>
    </>
  )
}
export default Alarms
