import { isIpPortMode } from './api/v1/helpers'
import {
    CoaxPortMode,
    ComprimatoPortMode,
    InputPort,
    IpPortMode,
    MatroxPortMode,
    OutputPort,
    PortMode,
    PortType,
    SrtMode,
    VideonPortMode,
} from './api/v1/types'
import { InputPortDbConfiguration, OutputPortDbConfiguration } from 'common/types'

export const TCP_EDGE_CONTROL_HTTP_PORT = 8081

// TODO: We don't check for conflicts on the thumb port to be backwards compatible with potentially existing services.
// This could cause issues if the thumb appliance is located on the video node.
export const TCP_THUMB_EDGE_CONTROL_HTTP_PORT = 8082

// TODO: EDGE-4269: Use GlobalSettings.ristTunnelBasePort instead of UDP_TUNNEL_PORT_RANGE_START when checking for reserved ports.
export const UDP_TUNNEL_PORT_RANGE_START = 20000
export const UDP_TUNNEL_PORT_RANGE_END = 20999

export const UDP_HANDOVER_PORT_RANGE_START = 30000
export const UDP_HANDOVER_PORT_RANGE_END = 30999

export function isIpBasedProtocol(mode: PortMode): mode is IpPortMode {
    return mode in IpPortMode
}
export function isUdpBasedProtocol(mode: PortMode): mode is Exclude<IpPortMode, 'rtmp'> {
    return isIpBasedProtocol(mode) && !isTcpBasedProtocol(mode)
}
export function isTcpBasedProtocol(mode: PortMode): mode is IpPortMode.rtmp {
    return mode === IpPortMode.rtmp
}

export function getLocalPortFromPortDbConfiguration(
    configuration: InputPortDbConfiguration | OutputPortDbConfiguration
): number {
    // Rendezvous 'remotePort' is also the local listen port
    if ('remotePort' in configuration && 'srtMode' in configuration && configuration.srtMode === SrtMode.rendezvous)
        return configuration.remotePort
    if ('localPort' in configuration && configuration.localPort) return configuration.localPort
    return 0
}

export function getLocalPortNumbersUsedByInputPort(port: InputPort): number[] {
    let localBasePort: number | undefined = undefined

    switch (port.mode) {
        case IpPortMode.generator:
            if (typeof port.port === 'number') {
                localBasePort = port.port
            }
            break

        case IpPortMode.udp: // fallthrough
        case IpPortMode.rtp: // fallthrough
        case IpPortMode.rist: // fallthrough
        case IpPortMode.rtmp:
            localBasePort = port.port
            break

        case IpPortMode.srt: {
            switch (port.srtMode) {
                case SrtMode.rendezvous:
                    localBasePort = port.remotePort
                    break
                case SrtMode.caller: // fallthrough
                case SrtMode.listener:
                    localBasePort = port.localPort
                    break
            }
            break
        }
    }

    return localBasePort
        ? getLocalPortNumbersUsedByInputProtocol({
              mode: port.mode,
              localBasePort,
              isFec: 'fec' in port && !!port.fec,
              isRtcp: port.mode === IpPortMode.rtp && port.rtcp === true,
              isMulticast: 'multicastAddress' in port && !!port.multicastAddress,
          })
        : []
}

export function getLocalPortNumbersUsedByOutputPort(port: OutputPort): number[] {
    let localBasePort: number | undefined = undefined

    switch (port.mode) {
        case IpPortMode.rtp: // fallthrough
        case IpPortMode.udp: // fallthrough
        case IpPortMode.rist: // fallthrough
            localBasePort = port.localPort
            break

        case IpPortMode.srt: {
            switch (port.srtMode) {
                case SrtMode.rendezvous:
                    localBasePort = port.remotePort
                    break
                case SrtMode.caller: // fallthrough
                case SrtMode.listener:
                    localBasePort = port.localPort
                    break
            }
            break
        }
    }

    return localBasePort
        ? getLocalPortNumbersUsedByOutputProtocol({
              mode: port.mode,
              localBasePort,
          })
        : []
}

export function getLocalPortNumbersUsedByInputProtocol(port: {
    mode: PortMode
    localBasePort: number
    isFec: boolean
    isRtcp: boolean
    isMulticast: boolean
}): number[] {
    const portNumbers = new Set<number>()

    switch (port.mode) {
        case IpPortMode.rist:
            portNumbers.add(port.localBasePort)
            portNumbers.add(port.localBasePort + 1) // RTCP
            break

        case IpPortMode.udp:
            // EDGE-4003: Don't check for conflicts against multicast ports
            if (!port.isMulticast) {
                portNumbers.add(port.localBasePort)
            }
            break

        case IpPortMode.rtp:
            // EDGE-4003: Don't check for conflicts against multicast ports
            if (!port.isMulticast) {
                portNumbers.add(port.localBasePort)
                if (port.isRtcp) {
                    portNumbers.add(port.localBasePort + 1)
                }
                if (port.isFec) {
                    portNumbers.add(port.localBasePort + 2)
                    portNumbers.add(port.localBasePort + 4)
                }
            }
            break

        case IpPortMode.rtmp: // fallthrough
        case IpPortMode.srt: // fallthrough
        case IpPortMode.generator:
            portNumbers.add(port.localBasePort)
            break
    }
    return Array.from(portNumbers)
}

export function getLocalPortNumbersUsedByOutputProtocol(port: { mode: PortMode; localBasePort: number }): number[] {
    const portNumbers = new Set<number>()

    switch (port.mode) {
        case IpPortMode.udp: // fallthrough
        case IpPortMode.rtp: // fallthrough
        case IpPortMode.srt: // fallthrough
        case IpPortMode.rist:
            portNumbers.add(port.localBasePort)
            break
    }
    return Array.from(portNumbers)
}

export function physicalPortTypeMatchesPortMode(
    physicalPortType: PortType,
    mode: (InputPort | OutputPort)['mode']
): boolean {
    switch (physicalPortType) {
        case PortType.coax:
            return mode in CoaxPortMode || mode in MatroxPortMode || mode === ComprimatoPortMode.comprimatoSdi
        case PortType.ndi:
            return mode === ComprimatoPortMode.comprimatoNdi
        case PortType.videon:
            return mode in VideonPortMode
        case PortType.ip: {
            return isIpPortMode(mode)
        }
    }
}
