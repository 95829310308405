import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/fp/get'

import { OutputRecipientList } from 'common/api/v1/types'
import {
  clearOutputList,
  clearOutputLists,
  createOutputList,
  getOutputList,
  getOutputLists,
  removeOutputList,
  updateOutputList,
} from '../actions/outputListsActions'

import { logoutUser } from '../actions/userActions'
import { isOneOf } from '../actions'
import { createLoadingReducer } from './shared'

interface State {
  formErrors?: Array<{ name: string; reason: string }>
  loading: boolean
  outputList?: OutputRecipientList
  outputLists: Array<OutputRecipientList>
  saving?: boolean
  total: number
}
export const initialStateOutputLists: State = {
  outputLists: [],
  loading: false,
  total: 0,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(removeOutputList, getOutputLists)

const outputListsSlice = createSlice({
  name: 'outputLists',
  initialState: initialStateOutputLists,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getOutputLists.fulfilled,
        (state, { payload: { items: outputLists, total } }): State => ({
          ...state,
          outputLists,
          total,
        }),
      )
      .addCase(getOutputList.fulfilled, (state, { payload: outputList }): State => ({ ...state, outputList }))
      .addCase(clearOutputList, (state): State => ({ ...state, outputList: undefined, formErrors: undefined }))
      .addCase(clearOutputLists, (state): State => ({ ...state, outputLists: [] }))
      .addCase(logoutUser.fulfilled, (): State => initialStateOutputLists)

      .addMatcher(
        isOneOf([updateOutputList.pending, createOutputList.pending]),
        (state): State => ({ ...state, saving: true }),
      )
      .addMatcher(
        isOneOf([createOutputList.fulfilled, updateOutputList.fulfilled]),
        (state): State => ({
          ...state,
          saving: undefined,
          formErrors: undefined,
        }),
      )
      .addMatcher(
        isOneOf([updateOutputList.rejected, createOutputList.rejected]),
        (state, { payload }): State => ({
          ...state,
          saving: false,
          formErrors: get('errorInfo.origin.data.detail', payload),
        }),
      )
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default outputListsSlice.reducer
