import { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField'
import { AutoComplete, MultiValueAutoComplete } from '../../AutoComplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { listResult } from '../../../../utils'
import { SelectedFilters } from './SelectedFilters'
import Typography from '@mui/material/Typography'
import { clone } from 'common/util'
import { FilterBy, FilterTemplate } from './FilterTemplate'

export interface AddFilterViewProps {
  // All available filters to choose from
  availableFilters: FilterTemplate[]
  // All existing/previously configured filters
  initialFilters: FilterBy[]

  onFiltersUpdated: (filters: FilterBy[]) => void
}

export const AddFilterView = ({ initialFilters, availableFilters, onFiltersUpdated }: AddFilterViewProps) => {
  const firstAvailableFilter = availableFilters[0]
  const [filterBeingEdited, setFilterToEdit] = useState<FilterBy | undefined>(
    firstAvailableFilter ? makeEmptyInstance(firstAvailableFilter) : undefined,
  )

  // Acts as a template ("class") of filter being edited
  const filterTemplate = filterBeingEdited ? availableFilters.find((f) => f.key === filterBeingEdited.key) : undefined
  const [savedFilters, setSavedFilters] = useState(initialFilters)

  useEffect(() => {
    // Notify delegate each time filters are updated
    onFiltersUpdated(savedFilters)
  }, [onFiltersUpdated, savedFilters])

  let userInputComponent = null
  if (filterBeingEdited && filterTemplate) {
    switch (filterBeingEdited.operator) {
      case 'is': // fallthrough
      case 'matches': {
        const showAutoComplete = filterTemplate.possibleValues || filterTemplate.possibleValuesSync
        if (showAutoComplete) {
          const initialValue = filterBeingEdited?.value
            ? { id: filterBeingEdited.value, name: filterBeingEdited.displayName ?? filterBeingEdited.value }
            : null

          userInputComponent = (
            <AutoComplete
              key={`${filterBeingEdited.key}-${filterBeingEdited.value}`}
              placeholder={filterBeingEdited.operator}
              isClearable={true}
              getOptionLabel={(o) => o.name}
              api={async (urlParams) => {
                const searchText = urlParams.filter
                const options = filterTemplate.possibleValuesSync
                  ? filterTemplate.possibleValuesSync(searchText)
                  : filterTemplate.possibleValues && searchText // Require searchText to reduce number of requests towards backend
                  ? await filterTemplate.possibleValues(searchText)
                  : []
                return listResult(options.map((o) => ({ id: o.value, name: o.displayName })))
              }}
              initialValue={initialValue}
              onValueSelected={(newValue) => {
                setFilterToEdit({
                  ...filterBeingEdited,
                  value: newValue?.id ?? '',
                  displayName: newValue?.name ?? undefined,
                })
              }}
              dataTestId={'addFilterView-autocomplete-single'}
            />
          )
        } else {
          userInputComponent = (
            <TextField
              key={filterBeingEdited.key}
              data-testid={'editFilterView-textfield'}
              label={filterBeingEdited.operator}
              value={filterBeingEdited.value}
              fullWidth
              onChange={(e) => {
                setFilterToEdit({
                  ...filterBeingEdited,
                  value: e.target.value,
                })
              }}
            />
          )
        }
        break
      }

      case 'isOneOf':
        userInputComponent = (
          <MultiValueAutoComplete
            key={`${filterBeingEdited.key}-${filterBeingEdited.values.join('-')}`}
            placeholder={'is one of'}
            api={async (urlParams) => {
              const searchText = urlParams.filter
              // Require searchText to reduce number of requests towards backend
              const options = filterTemplate.possibleValuesSync
                ? filterTemplate.possibleValuesSync(searchText)
                : filterTemplate.possibleValues && searchText // Require searchText to reduce number of requests towards backend
                ? await filterTemplate.possibleValues(searchText)
                : []
              return listResult(options)
            }}
            initialValue={filterBeingEdited.values}
            onValueSelected={(newValues) => {
              setFilterToEdit({
                ...filterBeingEdited,
                values: newValues,
              })
            }}
            dataTestId={'editFilterView-autocomplete'}
          />
        )
        break
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width: '30%' }}>
          <AutoComplete
            placeholder={'Filter by'}
            api={() => Promise.resolve(listResult(availableFilters.map((e) => ({ id: e.key, name: e.key }))))}
            initialValue={filterTemplate ? { id: filterTemplate.key, name: filterTemplate.key } : null}
            onValueSelected={(v) => {
              const selectedTemplate = availableFilters.find((e) => e.key === v?.id)
              setFilterToEdit(selectedTemplate ? makeEmptyInstance(selectedTemplate) : undefined)
            }}
            getOptionLabel={(v) => v.name}
            isClearable={true}
            dataTestId={'addFilterTestId'}
          />
        </Box>
        <Box sx={{ width: '60%', marginLeft: 1 }}>{userInputComponent}</Box>
        <Box>
          <Button
            sx={{ flexShrink: 0, marginLeft: 1 }}
            disabled={!filterBeingEdited || isFilterEmpty(filterBeingEdited)}
            variant="contained"
            color="primary"
            onClick={() => {
              if (filterBeingEdited) {
                setSavedFilters((savedFilters) => {
                  const index = savedFilters.findIndex((e) => e.key === filterBeingEdited.key)
                  if (index === -1) {
                    // Add new filter
                    return [...savedFilters, filterBeingEdited]
                  } else {
                    // Add to existing filter
                    const copy = [...savedFilters]
                    const existingFilter = clone(copy[index])
                    if ('value' in existingFilter) {
                      existingFilter.value = (filterBeingEdited as typeof existingFilter).value
                      existingFilter.displayName = (filterBeingEdited as typeof existingFilter).displayName
                    } else if ('values' in existingFilter) {
                      existingFilter.values = [
                        ...existingFilter.values,
                        ...(filterBeingEdited as typeof existingFilter).values,
                      ].reduce((acc, current) => {
                        if (!acc.find((v) => v.value === current.value)) acc.push(current)
                        return acc
                      }, [] as typeof existingFilter.values)
                    }
                    copy[index] = existingFilter
                    return copy
                  }
                })

                setFilterToEdit(makeEmptyInstance(filterBeingEdited))
              }
            }}
            data-testid={'addFilterView-add-btn'}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <SelectedFilters selectedFilters={savedFilters} onFiltersUpdated={setSavedFilters} />
      </Box>
      {savedFilters.length === 0 && <Typography key={'key_noFilterSelected'}>No filters selected</Typography>}
    </Box>
  )
}

function makeEmptyInstance({ key, operator }: Pick<FilterTemplate, 'key' | 'operator'>): FilterBy {
  switch (operator) {
    case 'is': // fallthrough
    case 'matches':
      return { key, operator, value: '' }
    case 'isOneOf':
      return { key, operator, values: [] }
  }
}

function isFilterEmpty(filter: FilterBy) {
  switch (filter.operator) {
    case 'is': // fallthrough
    case 'matches':
      return !filter.value
    case 'isOneOf':
      return filter.values.length === 0
  }
}
