import React from 'react'
import get from 'lodash/fp/get'
import omit from 'lodash/fp/omit'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'

import Pendable from '../../common/Pendable'
import { Pagination, PaginationProps } from './Pagination'
import ColumnHeader, { ColumnHeaderProps } from './ColumnHeader'
import Divider from '@mui/material/Divider'

const styles = {
  wrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  cell: {
    borderBottom: 'none',
  },
}

interface CellProp extends TableCellProps {
  value: string | React.ReactNode
  noTypography?: boolean
}
const Cell = ({ value, noTypography, ...rest }: CellProp) => (
  <TableCell {...rest}>{noTypography ? value : <Typography component="div">{value}</Typography>}</TableCell>
)

function filterFn<TEntity, TSort extends string = string>(
  column: ColumnProps<TEntity, TSort> | null,
): column is ColumnProps<TEntity, TSort> {
  return column !== null
}

export interface ColumnProps<TEntity, TSort extends string = string> {
  title?: React.ReactNode
  getValue: (ent: TEntity, index: number) => React.ReactNode
  props?: TableCellProps & { noTypography?: boolean }
  headerProps?: TableCellProps
  sorting?: ColumnHeaderProps<TSort>['sorting']
}

export interface TableConfig<TEntity, TSort extends string = string>
  extends Array<ColumnProps<TEntity, TSort> | null> {}

export interface TableProps<TEntity, TSort extends string = string> {
  config: TableConfig<TEntity, TSort>
  data: Array<TEntity>
  getCustomEntityId?: (row: TEntity) => string
  props?: MuiTableProps
  emptyMessageComponent?: React.ReactNode
  hasBorders?: boolean
  id?: string
  isSmall?: boolean
  rowProps?: (ent: TEntity) => TableRowProps
  pagination?: PaginationProps
  noHeader?: boolean
  pending?: boolean
  footer?: () => JSX.Element
}

/**
 * Common table component
 * @param config - array of column descriptions {
 *   title - column title,
 *   getValue - function to get the value to show for each row,
 *   props - MaterialUI cell properties for rows cells (and noTypography - if we don't want the value to be wrapped with Typography)
 *   headerProps - MaterialUI cell properties for header row cell
 *   sorting - sorting properties
 * }
 * @param data - array of entities
 * @param getCustomEntityId - Pass this when you wish to pass a custom entity id/key fn, e.g. if the entity id consists of multiple properties. Defaults to the 'id' of the entity (if any), else the entity's index.
 * @param props - MaterialUI Table properties
 * @param id
 * @param hasBorders - whether we should show lines borders
 * @param emptyMessageComponent - what should we show if no entities present
 * @param isSmall - mostly there are two default table sizes for paddings
 * @param rowProps - function to get MaterialUI table row properties based on row data
 * @param pagination - should we show pagination and how we should do it
 * @param noHeader - should we hide header row
 * @param pending - whether we should show loading spinner
 */
export const Table: <T, TSort extends string = string>(
  props: TableProps<T, TSort>,
) => React.ReactElement<TableProps<T, TSort>> = ({
  config: initConfig,
  data,
  getCustomEntityId,
  props,
  id,
  hasBorders,
  emptyMessageComponent = <div />,
  isSmall,
  rowProps,
  pagination,
  noHeader,
  pending,
  footer,
}) => {
  const config = initConfig.filter(filterFn)
  const getEntityId = (entity: (typeof data)[0], index: number): string =>
    getCustomEntityId?.(entity) || get('id', entity) || index
  return (
    <>
      <Pendable pending={!!pending} cover={!!data.length} id={id}>
        {data.length ? (
          <Box sx={styles.wrapper}>
            <MuiTable id={id} size={isSmall ? 'small' : 'medium'} {...props}>
              {!noHeader && (
                <TableHead>
                  <TableRow>
                    {config.map(({ title = '', props, headerProps, sorting }, ind, { length }) => (
                      <TableCell
                        key={ind}
                        sx={isSmall && !hasBorders ? { root: styles.cell } : undefined}
                        padding={isSmall && (!ind || ind === length - 1) ? 'none' : undefined}
                        data-table-column={title}
                        {...(headerProps ? headerProps : omit(['noTypography'], props))}
                      >
                        <ColumnHeader title={title} sorting={sorting} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {data.map((entity, i) => (
                  <TableRow hover key={getEntityId(entity, i)} {...(rowProps ? rowProps(entity) : null)}>
                    {config.map(({ getValue, props, title }, ind, { length }) => (
                      <Cell
                        sx={isSmall && !hasBorders ? { root: styles.cell } : undefined}
                        key={ind}
                        value={getValue(entity, i)}
                        data-cell-name={`${title}-cell`}
                        padding={isSmall && (!ind || ind === length - 1) ? 'none' : undefined}
                        {...props}
                      />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              {footer && <TableFooter>{footer()}</TableFooter>}
            </MuiTable>
          </Box>
        ) : (
          <>{emptyMessageComponent}</>
        )}
      </Pendable>
      {pagination && (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'background.paper',
          }}
        >
          <Divider />
          <Pagination {...pagination} id={id} />
        </Box>
      )}
    </>
  )
}
