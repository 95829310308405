import { useFormikContext } from 'formik'
import { Paper, Select, TextInput } from '../../../components/common/Form'
import { InputFormProps, MAX_MAX_BITRATE_MBPS } from '../Edit/InputForm'
import Divider from '@mui/material/Divider'
import { useEffect } from 'react'

export const TranscodingSection = () => {
  const { values, setFieldValue } = useFormikContext<InputFormProps>()
  const ffmpegParams =
    values?.deriveFrom?.ingestTransform?.type === 'transcode'
      ? values?.deriveFrom?.ingestTransform.ffmpegParams
      : undefined
  const isVideoCodec = !!ffmpegParams?.codec
  const isOverlay = !!ffmpegParams?.overlay
  const isResolution = !!ffmpegParams?.resolution
  const isPixelFormat = !!ffmpegParams?.pixelFormat

  useEffect(() => {
    // If codec is not set, reset dependent fields
    if (!isVideoCodec) {
      const params = 'deriveFrom.ingestTransform.ffmpegParams'

      setFieldValue('transcodeBitrateMbps', '')
      setFieldValue(`${params}.resolution`, '')
      setFieldValue(`${params}.overlay`, '')
      setFieldValue(`${params}.pixelFormat`, '')
      setFieldValue(`${params}.framerate`, '')
    }
  }, [isVideoCodec, setFieldValue])

  return (
    <Paper title="Transcoding">
      <Select
        label="Video codec"
        name="deriveFrom.ingestTransform.ffmpegParams.codec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'H.264 (AVC)', value: 'h264' },
          { name: 'H.265 (HEVC)', value: 'h265' },
        ]}
        tooltip="Change codec using hardware acceleration"
      />
      {isVideoCodec && (
        <>
          <TextInput
            label="Bitrate (Mbps)"
            name="transcodeBitrateMbps"
            noNegative
            validators={{
              number: {
                lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
                greaterThan: 0,
                message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
              },
            }}
            type="number"
            tooltip="Choose the compression level by selecting a bitrate for the transformed input"
          />
          <Select
            label="Framerate"
            name="deriveFrom.ingestTransform.ffmpegParams.framerate"
            options={[{ name: 'Keep original', value: '' }].concat(
              ['25', '29.97', '50', '59.94'].map((framerate) => ({ name: framerate, value: framerate })),
            )}
            tooltip="Convert framerate up or down, dropping extra frames or duplicating intermediate frames"
          />
          <Select
            label={!isResolution ? 'Pixel format' : 'Pixel format (cannot be used with resolution scaling)'}
            name="deriveFrom.ingestTransform.ffmpegParams.pixelFormat"
            options={[
              { name: 'Keep original', value: '' },
              { name: 'YUV 4:2:0 Progressive', value: 'yuv420p' },
              { name: 'YUV 4:2:2 Progressive', value: 'yuv422p', disabled: true },
              { name: 'YUV 4:4:4 Progressive', value: 'yuv444p' },
            ]}
            tooltip="Pixel format choice depends on the hardware accelerator's capability. Cannot be used with resolution scaling"
            disabled={isResolution}
          />
          <Select
            label={!isResolution ? 'Overlay' : 'Overlay (cannot be used with resolution scaling)'}
            name="deriveFrom.ingestTransform.ffmpegParams.overlay"
            options={[
              { name: 'None', value: '' },
              { name: 'Net Insight demo', value: 'demo' },
            ]}
            tooltip="Add a channel logo or other overlays to the transformed input. Cannot be used with resolution scaling"
            disabled={isResolution}
          />
          <Select
            label={
              !(isOverlay || isResolution) ? 'Resolution scaling' : 'Resolution scaling (cannot be used with overlay)'
            }
            name="deriveFrom.ingestTransform.ffmpegParams.resolution"
            options={[
              { name: 'Keep original', value: '' },
              { name: '2160p', value: '3840:2160' },
              { name: '1080p', value: '1920:1080' },
              { name: '720p', value: '1280:720' },
            ]}
            tooltip="Scale the resolution of the transformed input (Only available with NVIDIA accelerators). Cannot be used with overlay or pixel format"
            disabled={isOverlay || isPixelFormat}
          />
        </>
      )}

      <Divider sx={{ width: '100%', mt: 3 }} />

      <Select
        label="Audio codec"
        name="deriveFrom.ingestTransform.ffmpegParams.audioCodec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'AAC', value: 'aac' },
          { name: 'MP2', value: 'mp2' },
          { name: 'AC3', value: 'ac3' },
        ]}
        tooltip="Change audio codec"
      />
    </Paper>
  )
}
