import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { type BackupInit, GlobalSettings, ImageName, TlsCertRead, TlsCertWrite } from 'common/api/v1/types'
import { AppDispatch, ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { ISettingsApi } from '../../api/settings/api'

export interface FileInfo {
  name: string
  type: string
  size: number
  base64: string
  file: File
}

export const getSettings = createAsyncThunk<{ settings: GlobalSettings; tls: TlsCertRead }, void, ThunkApi>(
  'settings/getSettings',
  async (_, { extra: { api } }) => {
    const [settings, tls] = await Promise.all([api.settingsApi.getSettings(), api.tlsApi.getTls()])
    return { settings, tls }
  },
)

export const getSettingsAsync = async ({
  dispatch,
  settingsApi,
}: {
  dispatch: AppDispatch
  settingsApi: ISettingsApi
}): Promise<GlobalSettings> => {
  try {
    return await settingsApi.getSettings()
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch settings' }))
    throw err
  }
}

export const saveSettings = createAsyncThunk<GlobalSettings, GlobalSettings, ThunkApi>(
  'settings/saveSettings',
  async (payload, { dispatch, extra: { api } }) => {
    try {
      const res = await api.settingsApi.saveSettings(payload)
      dispatch(enqueueSuccessSnackbar('Global settings updated'))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update global settings' }))
      throw err
    }
  },
)

export const createBackup = createAsyncThunk<void, BackupInit, ThunkApi>(
  'settings/createBackup',
  async (payload, { dispatch, extra: { api } }) => {
    try {
      await api.settingsApi.createBackupAsync(payload)
      dispatch(enqueueSuccessSnackbar(`${payload.type} backup job created`))
      return
    } catch (error) {
      dispatch(enqueueErrorSnackbar({ error, operation: `create ${payload.type} backup` }))
      throw error
    }
  },
)

export const setImage = createAction<{
  name: ImageName
  file: FileInfo | undefined
}>('settings/setImage')
export const setImageToDelete = createAction<ImageName>('settings/setImageToDelete')

export const saveImages = createAsyncThunk<void, { snackbar?: false }, ThunkApi>(
  'settings/saveImages',
  async ({ snackbar }, { dispatch, getState, extra: { api } }) => {
    try {
      const { serviceProvider, product, favicon, toDelete } = getState().settingsReducer
      const requests = []
      if (serviceProvider) {
        requests.push(api.imageApi.setImage({ name: ImageName.serviceProvider, image: serviceProvider.base64 }))
      }
      if (product) {
        requests.push(api.imageApi.setImage({ name: ImageName.product, image: product.base64 }))
      }
      if (favicon) {
        requests.push(api.imageApi.setImage({ name: ImageName.favicon, image: favicon.base64 }))
      }
      if (toDelete[ImageName.serviceProvider]) {
        requests.push(api.imageApi.deleteImage(ImageName.serviceProvider))
      }
      if (toDelete[ImageName.product]) {
        requests.push(api.imageApi.deleteImage(ImageName.product))
      }
      if (toDelete[ImageName.favicon]) {
        requests.push(api.imageApi.deleteImage(ImageName.favicon))
      }
      await Promise.all(requests)
      if (snackbar) dispatch(enqueueSuccessSnackbar('Images saved successfully'))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update image' }))
      throw err
    }
  },
)

export const saveTls = createAsyncThunk<TlsCertRead, TlsCertWrite, ThunkApi>(
  'settings/saveTls',
  async (payload, { dispatch, extra: { api } }) => {
    try {
      const res = await api.tlsApi.saveTls(payload)
      dispatch(enqueueSuccessSnackbar('TLS certificate saved successfully'))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update tls settings' }))
      throw err
    }
  },
)

export const setDevMode = createAction('settings/devMode', (enabled: boolean) => {
  if (enabled) localStorage.setItem('dev-mode', 'enabled')
  else localStorage.removeItem('dev-mode')
  return { payload: enabled }
})
