import { PropsWithChildren, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Chip from '@mui/material/Chip'

import { AppDispatch, GlobalState } from '../../../store'
import { setDevMode } from '../../../redux/actions/settingsActions'
import { pluralize } from '../../../utils'

export default function DevModeSwitch({ children }: PropsWithChildren<unknown>) {
  const [clicks, setClicks] = useState(0)
  const inc = () => setClicks((i) => i + 1)
  const dispatch = useDispatch<AppDispatch>()

  const { devMode } = useSelector(
    ({ settingsReducer }: GlobalState) => ({
      devMode: settingsReducer.devMode,
    }),
    shallowEqual,
  )

  useEffect(() => {
    const n = 7 - clicks
    if (n === 0) {
      dispatch(setDevMode(true))
      // eslint-disable-next-line no-console
      console.log('Development mode activated')
      setClicks(0)
    } else if (clicks > 0) {
      // eslint-disable-next-line no-console
      console.log(`Click ${pluralize(n, 'more time')} to activate development mode`)
    }
  }, [dispatch, clicks])

  if (devMode)
    return (
      <>
        {children}
        <Chip
          sx={{ m: '16px', mb: '8px', mt: 0 }}
          label="Dev mode"
          size="small"
          color="warning"
          onDelete={() => dispatch(setDevMode(false))}
        />
      </>
    )
  return <div onClick={inc}>{children}</div>
}
