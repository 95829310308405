import React from 'react'
import { createPortal } from 'react-dom'
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik'
import get from 'lodash/get'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import { Address, Output, ZixiLink, ZixiLinkMode, ZixiPushOutputPort } from 'common/api/v1/types'
import { GridItem, Paper, Select, TextInput } from '../../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../../utils'

export enum LinkFields {
  localIp = 'localIp',
  remoteIp = 'remoteIp',
  remotePort = 'remotePort',
  rateLimit = 'rateLimit',
}
export const linkSetDefault = createDefaultFiledValues(Object.keys(LinkFields), [], {
  [LinkFields.remotePort]: 2088,
  [LinkFields.rateLimit]: 1000000,
}) as { [key: string]: number | string }

export type ZixiPushOutput = Output & { ports: Array<ZixiPushOutputPort> }

const LinksForm = (props: FieldArrayRenderProps & { isStandby: boolean; addresses: Address[] }) => {
  const form: FormikProps<ZixiPushOutput> = props.form
  const { name, remove, isStandby } = props
  const links = get(form.values, name)
  return (
    <Grid item xs={12}>
      {links &&
        links.length > 0 &&
        (isStandby ? [links[0]] : links).map((_: ZixiLink, ind: number) => {
          const namePrefix = `${name}.${ind}`
          const localAddressSelector = `${namePrefix}.${LinkFields.localIp}`
          return (
            <Paper key={ind}>
              <Select
                name={localAddressSelector}
                label="Local address"
                options={makeAddressOptions(get(form.values, localAddressSelector), props.addresses)}
                required
                newLine
              />
              <TextInput
                name={`${namePrefix}.${LinkFields.remoteIp}`}
                label="Remote host"
                required
                validators={{ ipOrHostname: {} }}
              />
              <TextInput
                name={`${namePrefix}.${LinkFields.remotePort}`}
                label="Remote UDP port"
                required
                type="number"
                validators={{ port: { isUdp: true } }}
              />
              {!isStandby && (
                <TextInput
                  name={`${namePrefix}.${LinkFields.rateLimit}`}
                  label="Rate limit (kbps)"
                  type="number"
                  noNegative
                  validators={{
                    number: {
                      lessThanOrEqualTo: Math.pow(2, 32) - 1,
                      message: `Must be no more than ${Math.pow(2, 32) - 1}`,
                    },
                  }}
                />
              )}
              {links.length > 1 && !isStandby && (
                <GridItem newLine>
                  <Button variant="outlined" color="primary" onClick={() => remove(ind)}>
                    Remove link
                  </Button>
                </GridItem>
              )}
            </Paper>
          )
        })}
    </Grid>
  )
}
interface LinksArrayProps {
  form: FormikProps<ZixiPushOutput>
  addresses: Address[]
  namePrefix: string
}
interface LinksPaperProps extends LinksArrayProps {
  form: FormikProps<ZixiPushOutput>
  title: string
  isFirstSet: boolean
}
const LinksPaper: React.FunctionComponent<LinksPaperProps> = ({ form, title, addresses, namePrefix, isFirstSet }) => {
  const prop = `${namePrefix}.${isFirstSet ? 'linkSet1' : 'linkSet2'}`
  const port: ZixiPushOutputPort = get(form.values, namePrefix)
  const isStandby = port.linkMode === ZixiLinkMode.standby
  const linkSet = port[isFirstSet ? 'linkSet1' : 'linkSet2']

  return (
    <Paper
      className="outlined"
      title={title}
      actionsPane={
        port.linkSet1.length < 3 && !isStandby
          ? [
              {
                id: `add-link-btn-${isFirstSet ? 1 : 2}`,
                title: 'Add link',
                onClick: () =>
                  form.setFieldValue(
                    prop,
                    linkSet ? linkSet.concat({ ...linkSetDefault } as unknown as ZixiLink) : [{ ...linkSetDefault }],
                  ),
              },
            ]
          : []
      }
    >
      <FieldArray
        name={prop}
        render={(formikArrayHelpers: FieldArrayRenderProps) => {
          return (
            <LinksForm
              addresses={addresses}
              isStandby={port.linkMode === ZixiLinkMode.standby}
              {...formikArrayHelpers}
            />
          )
        }}
      />
    </Paper>
  )
}
const LinksArray: React.FunctionComponent<LinksArrayProps> = ({ form, namePrefix, addresses }) => {
  const container = document.getElementById('LinksArrayContainer')
  if (!container) return null
  const isStandby = get(form.values, `${namePrefix}.linkMode`) === ZixiLinkMode.standby
  return createPortal(
    <>
      <LinksPaper
        form={form}
        title={`Primary${isStandby ? '' : ' links'}`}
        isFirstSet={true}
        addresses={addresses}
        namePrefix={namePrefix}
      />
      <LinksPaper
        form={form}
        title={`Standby${isStandby ? '' : ' links'}`}
        isFirstSet={false}
        addresses={addresses}
        namePrefix={namePrefix}
      />
    </>,
    container,
  )
}

export default LinksArray
