import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { alpha, Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'

import { NavLink } from '../Link'
import { doesPathBelongTo } from '../../../utils/routes'

const styles = {
  listItem: {
    padding: (theme: Theme) => theme.spacing(1.2, 2) + ' !important', // FIXME: Remove important
    cursor: 'pointer',
  },
  link: {
    display: 'block',
    outline: 'none',
    transition: (theme: Theme) => theme.transitions.create('background'),
    '&:hover, &:focus': {
      background: (theme: Theme) => alpha(theme.palette.common.white, 0.15),
    },
    [`&.selected, &.selected:hover`]: {
      background: (theme: Theme) => theme.palette.primary.main,
    },
  },
}

const link = ({ theme }: { theme: Theme }) => ({
  display: 'block',
  outline: 'none',
  transition: theme.transitions.create('background'),
  '&:hover, &:focus': {
    background: alpha(theme.palette.common.white, 0.15),
  },
  [`&.selected, &.selected:hover`]: {
    background: theme.palette.primary.main,
  },
})

const StyledNavLink = styled(NavLink)(link)

export interface SidebarMenuItemProps {
  id?: string
  children?: Array<SidebarMenuItemProps | null>
  icon?: React.ReactElement
  url?: string
  name: React.ReactElement | string
  iconColor?: string | false
  divider?: boolean
  onClick?: () => void
}

/**
 * Sidebar menu item
 * @param name - text in it
 * @param url - url to lead to, including cached urlParams
 * @param icon - icon to show
 * @param children - array of subItems. Can't be used with `onClick`
 * @param iconColor - if wee need to set other color for the icon
 * @param onClick - if we need to call some action instead of leading somewhere. Can't be used with `children`
 * @param id
 */
export const SidebarMenuItem = ({ name, url, icon, children, iconColor, onClick, id }: SidebarMenuItemProps) => {
  const currentPath = useLocation().pathname
  const shouldBeExpanded =
    children?.some((child) => {
      if (child?.url) {
        const element = document.createElement('a')
        element.href = child.url
        const menuItemPath = element.pathname
        return doesPathBelongTo(currentPath, menuItemPath)
      }
      return false
    }) || false

  const [expanded, setExpanded] = useState(shouldBeExpanded)

  useEffect(() => {
    setExpanded(shouldBeExpanded)
  }, [currentPath])

  const handleOnClick = children ? () => setExpanded(!expanded) : onClick
  const leftPadding = '34px'
  const listItem = (
    <ListItem dense onClick={handleOnClick} sx={styles.listItem} id={id} component="div">
      {!!icon && <ListItemIcon sx={{ minWidth: leftPadding, color: iconColor || 'inherit' }}>{icon}</ListItemIcon>}
      <ListItemText sx={{ paddingLeft: icon ? 0 : leftPadding }} primary={name} />
      {!!children && (expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />)}
    </ListItem>
  )

  return url ? (
    <StyledNavLink to={url} className={({ isActive }) => (isActive ? 'selected' : '')}>
      {listItem}
    </StyledNavLink>
  ) : (
    <>
      {listItem}
      {!!children && (
        <Collapse in={expanded} data-testid={`${name}-sub`}>
          {children.map((child, ind) => {
            const key = child?.url || `${name}-${ind}`
            return (
              !!child && (
                <React.Fragment key={key}>
                  <SidebarMenuItem {...child} />
                  {child.divider && <Divider variant="middle" />}
                </React.Fragment>
              )
            )
          })}
        </Collapse>
      )}
    </>
  )
}
