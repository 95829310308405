import { FormikProps } from 'formik'
import get from 'lodash/get'

import { Address, Output } from 'common/api/v1/types'
import { Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions } from '../../../../../utils'

export enum UdpFields {
  sourceAddress = 'sourceAddress',
  address = 'address',
  port = 'port',
  ttl = 'ttl',
}
export const udpDefaults = () => createDefaultFiledValues(Object.keys(UdpFields), [], { ttl: 64 })
export const getUdpFieldsToSave = () => [UdpFields.sourceAddress, UdpFields.address, UdpFields.port, UdpFields.ttl]

interface UdpFormProps {
  form: FormikProps<Output>
  addresses: Array<Address>
  namePrefix: string
}
const UdpForm = ({ form: { values }, addresses, namePrefix }: UdpFormProps) => {
  const sourceAddressSelector = `${namePrefix}.${UdpFields.sourceAddress}`
  const selectedSourceAddress = get(values, sourceAddressSelector)
  const addressesAndEmptyAddress = [{ address: '', netmask: '' }, ...addresses]
  const addressOptions = [{ name: 'any', value: '' }, ...makeAddressOptions(selectedSourceAddress, addresses)]
  return (
    <>
      <Select
        name={sourceAddressSelector}
        label="Source Address"
        options={addressOptions}
        newLine
        validators={{
          addressIn: { addresses: addressesAndEmptyAddress },
        }}
      />
      <TextInput
        name={`${namePrefix}.${UdpFields.address}`}
        label="Destination address"
        required
        type="text"
        validators={{ ip: {} }}
        newLine
      />
      <TextInput
        name={`${namePrefix}.${UdpFields.port}`}
        label="Destination Port"
        required
        type="number"
        noNegative
        validators={{
          port: { isUdp: true },
        }}
      />
      <TextInput
        name={`${namePrefix}.${UdpFields.ttl}`}
        label="TTL"
        required
        type="number"
        validators={{ number: { greaterThanOrEqualTo: 1, lessThanOrEqualTo: 255 } }}
      />
    </>
  )
}

export default UdpForm
