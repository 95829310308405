import { createSlice } from '@reduxjs/toolkit'
import { onThumbnailsFetched } from '../actions/thumbnailActions'
import { InputThumbnailMap, ThumbnailFetcher } from '../../thumbnail/ThumbnailFetcher'

interface State {
  thumbnails: InputThumbnailMap
}
const initialState: State = {
  thumbnails: {},
}

const thumbnailsSlice = createSlice({
  name: 'thumbnails',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onThumbnailsFetched, (state, { payload: inputThumbnailMap }): State => {
      const allThumbs = { ...state.thumbnails, ...inputThumbnailMap }

      for (const path of Object.keys(allThumbs)) {
        const thumbnail = allThumbs[path]
        if (ThumbnailFetcher.isThumbnailStale(thumbnail)) {
          delete allThumbs[path]
        }
      }
      return {
        ...state,
        thumbnails: allThumbs,
      }
    })
  },
})

export default thumbnailsSlice.reducer
