import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../common/Tooltip'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MuiSelect from '@mui/material/Select'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { ClearIcon } from '@mui/x-date-pickers'
import { useTheme } from '@mui/material/styles'

import { Appliance, ListResult, Role } from 'common/api/v1/types'
import { pluralize, useConfirmationDialog, usePageParams, useRegionsSelector, withDefaultPagination } from '../../utils'
import { restartAppliances } from '../../redux/actions/applianceActions'
import {
  AppliancesRequestParams,
  EnrichedApplianceWithOwner,
  EnrichedGroup,
  EnrichedUser,
  GroupsRequestParams,
} from '../../api/nm-types'
import { Api, AppDispatch, GlobalState } from '../../store'
import { ReduxSelectedFilters } from '../common/Filters/FilterView/SelectedFilters'
import {
  makeListApplianceFilter,
  mapApplianceFilterToUrlParam,
  mapUrlParamToApplianceFilter,
} from './listApplianceFilter'
import { FilterBy, filtersToUrlParams, urlParamsToFilters } from '../common/Filters/FilterView/FilterTemplate'
import { FilterPopup } from '../common/Filters/FilterView/AddFilterPopup'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import codeTheme from '../../themes/code'
import { getGroup, getGroups } from '../../redux/actions/groupActions'
const styles = {
  toolbarText: {
    flexGrow: 1,
  },
}

interface CommonActionsProps {
  appliances: EnrichedApplianceWithOwner[]
  selected: Array<Appliance['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  user: EnrichedUser
}

const BatchRestartButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()

  const onClick = () => {
    showConfirmation(() => {
      dispatch(restartAppliances({ ids: selected }))
      setSelected([])
    }, 'Restarting will affect all video streams on the appliances. After restart the appliances will run the latest software version. Do you want to continue?')
  }

  return (
    <Tooltip title={'Restart selected appliances'}>
      <span>
        <Button variant="contained" color="primary" onClick={onClick}>
          Restart
        </Button>
      </span>
    </Tooltip>
  )
}

const AddEdgeConnectDialog = ({ open, onClose, user }: { open: boolean; onClose: () => void; user: EnrichedUser }) => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [showCopied, setShowCopied] = useState<boolean>(false)
  const [groups, setGroups] = useState<EnrichedGroup[]>([])

  const fetchGroups = async () => {
    // Fetch all groups if super to allow selecting group
    if (user.role == Role.super) {
      return dispatch(getGroups({ pageNumber: '0', rowsPerPage: '1000' } as GroupsRequestParams)).then((res) => {
        return (res.payload as ListResult<EnrichedGroup>).items as EnrichedGroup[]
      })
    }

    // Fetch only user's group if not super, since it can only see the appliance secret of its own group
    return dispatch(getGroup(user.group)).then((res) => [res.payload] as EnrichedGroup[])
  }

  useEffect(() => {
    fetchGroups().then((groups) => {
      setGroups(groups)
      setCopyCommandGroup(groups[0]?.name || 'system')
    })
  }, [])

  const regionSel = useRegionsSelector(
    withDefaultPagination({
      pageNumber: '0',
      rowsPerPage: '1000',
    }),
  )
  const [copyCommandGroup, setCopyCommandGroup] = useState<string>('system')
  const [copyCommandName, setCopyCommandName] = useState<string>('edge-connect')
  const [copyCommandRegion, setCopyCommandRegion] = useState<string>('')

  const copyCommand = () => {
    if (!groups) return undefined

    const matchedGroup = groups.filter((group) => group.name === copyCommandGroup)
    if (matchedGroup.length === 0) return undefined

    const secret = matchedGroup[0].applianceSecret
    if (!secret) return undefined

    return (
      `curl --output connectit "${window.location.origin}/connectit"
chmod +x connectit
sudo SECRET="${secret}" ./connectit install edge-connect \\
  --url "${window.location.origin}" \\
  --name "${copyCommandName}"` +
      (copyCommandRegion
        ? ` \\
  --region "${copyCommandRegion}"`
        : '')
    )
  }

  const onCopy = () => {
    const cmd = copyCommand()
    if (!cmd) return

    navigator.clipboard.writeText(cmd)

    setShowCopied(true)
    setTimeout(() => {
      setShowCopied(false)
    }, 1500)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 3,
            mb: 1,
            width: 'auto',
            maxWidth: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Start an Edge Connect</Typography>

            <Tooltip
              title={
                <>
                  <Typography variant="inherit">
                    Run this command on a Linux machine to install Edge Connect.
                  </Typography>
                  <br />
                  <Typography variant="inherit">Docker must be installed before running the command.</Typography>
                </>
              }
              sx={{ ml: 1, mr: 1, scale: 1.1 }}
            >
              <HelpOutlineIcon />
            </Tooltip>

            {/* name, only alphanum, non-empty */}
            <TextField
              variant="outlined"
              size="small"
              label="Name"
              value={copyCommandName}
              error={copyCommandName.length === 0}
              onChange={(e) => setCopyCommandName(e.target.value.replace(/[^a-zA-Z0-9_.-]/g, ''))}
              sx={{ width: '150px', ml: 2 }}
            />
            {/* group */}
            {groups.length > 1 && (
              <FormControl variant="outlined" size="small" sx={{ minWidth: '100px', maxWidth: '300px', ml: 2 }}>
                <InputLabel>Group</InputLabel>
                <MuiSelect label="Group" value={copyCommandGroup} onChange={(e) => setCopyCommandGroup(e.target.value)}>
                  {groups.map((group) => (
                    <MenuItem key={group.id} value={group.name}>
                      {group.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            )}
            {/* region */}
            <FormControl variant="outlined" size="small" sx={{ minWidth: '170px', ml: 2 }}>
              <InputLabel>Region (Optional)</InputLabel>
              <MuiSelect
                label="Region (Optional)"
                value={copyCommandRegion}
                onChange={(e) => setCopyCommandRegion(e.target.value)}
                endAdornment={
                  copyCommandRegion.length > 0 && (
                    <InputAdornment sx={{ marginRight: '10px' }} position="end">
                      <IconButton
                        sx={{ scale: 0.8 }}
                        onClick={() => {
                          setCopyCommandRegion('')
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {regionSel.regions.map((region) => (
                  <MenuItem key={region.id} value={region.name}>
                    {region.name}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Box>
          <Divider sx={{ borderStyle: 'none' }} />
          <Box sx={{ position: 'relative', display: 'inline-block', maxWidth: '100%' }}>
            {navigator.clipboard && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  mt: 2,
                  mr: 1,
                  top: 4,
                  right: 4,
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    mr: 1,
                    opacity: showCopied ? 1 : 0,
                    visibility: showCopied ? 'visible' : 'hidden',
                    transition: 'opacity 0.3s ease, visibility 0.3s ease',
                  }}
                >
                  Copied!
                </Typography>
                <IconButton onClick={onCopy}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            )}

            <SyntaxHighlighter
              language="bash"
              style={codeTheme}
              codeTagProps={{
                style: { backgroundColor: 'transparent' }, // Background color behind the text
              }}
              customStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                border: '1px solid ' + theme.palette.divider,
                width: 'auto',
              }}
            >
              {copyCommand() || 'Loading...\n\n\n'}
            </SyntaxHighlighter>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CommonActions = ({ selected, setSelected, appliances, user }: CommonActionsProps) => {
  const showButtons = selected.length > 0
  const selectedAppliances = appliances.filter(({ id }) => selected.includes(id))
  const isOwnerOfAllSelected = selectedAppliances.every(({ _owner }) => _owner.id === user.group)
  const [showAddEdgeConnectDialog, setShowAddEdgeConnectDialog] = useState(false)

  const [filterPopupAnchor, setFilterPopupAnchor] = React.useState<HTMLElement | undefined>(undefined)
  const closePopup = () => {
    setFilterPopupAnchor(undefined)
  }
  const closePopupAndSaveFilters = (savedFilters: FilterBy[]) => {
    closePopup()
    populateUrlWithSelectedFilters(savedFilters)
  }

  const [_, setUrlParams] = usePageParams<Omit<AppliancesRequestParams, 'rowsPerPage' | 'asc' | 'desc'>>()
  const { cachedUrlParams } = useSelector(({ urlParamReducer }: GlobalState) => urlParamReducer, shallowEqual)
  const cachedRequestParams = cachedUrlParams['appliances']
  const appliedFilters = cachedRequestParams
    ? urlParamsToFilters(cachedRequestParams, mapUrlParamToApplianceFilter)
    : []
  const populateUrlWithSelectedFilters = (filters: FilterBy[]) => {
    const urlParams = filtersToUrlParams(filters, appliedFilters, mapApplianceFilterToUrlParam)
    setUrlParams({ ...urlParams, pageNumber: '0' }) // Reset page to 0 when applying filters
  }

  return (
    <>
      <Toolbar disableGutters>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button
            data-testid="add-filter-btn"
            variant="contained"
            onClick={(e) => setFilterPopupAnchor(e.currentTarget)}
          >
            Add filter
          </Button>
          <FilterPopup
            popupAnchor={filterPopupAnchor}
            onApplyClicked={closePopupAndSaveFilters}
            onCancelClicked={closePopup}
            initialFilters={appliedFilters}
            availableFilters={makeListApplianceFilter({
              applianceApi: Api.appliancesApi,
              groupApi: Api.groupsApi,
              regionApi: Api.regionApi,
            })}
          />
          <ReduxSelectedFilters
            urlParamCacheKey={'appliances'}
            mapUrlParamToFilterFn={mapUrlParamToApplianceFilter}
            mapFilterToUrlParamFn={mapApplianceFilterToUrlParam}
          />
        </div>

        <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {!!selected.length && (
            <Typography sx={styles.toolbarText} variant="subtitle1">
              {pluralize(selected.length, 'appliance')} selected
            </Typography>
          )}
          {!!selected.length && showButtons && (user.role === Role.super || isOwnerOfAllSelected) && (
            <div style={{ marginLeft: 8 }}>
              <BatchRestartButton selected={selected} setSelected={setSelected} />
            </div>
          )}
        </div>

        <Button
          variant="contained"
          color="primary"
          sx={{ marginLeft: 4 }}
          onClick={() => setShowAddEdgeConnectDialog(true)}
        >
          Add Edge Connect
        </Button>
      </Toolbar>

      <AddEdgeConnectDialog
        open={showAddEdgeConnectDialog}
        onClose={() => {
          setShowAddEdgeConnectDialog(false)
        }}
        user={user}
      />
    </>
  )
}

export default CommonActions
