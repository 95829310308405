import { Route, Routes } from 'react-router-dom'

import { SUB_PATH } from '../../utils/routes'
import { NotFound } from '../common/NotFound'
import { NetworksList } from './NetworksList'
import EditNetwork from './NetworkForm'

export const Networks = () => {
  return (
    <Routes>
      <Route path={SUB_PATH.UPDATE} element={<EditNetwork />} />
      <Route index element={<NetworksList />} />
      <Route path={SUB_PATH.NEW} element={<EditNetwork />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
