import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import mergeWith from 'lodash/mergeWith'
import { Form, Formik, FormikProps } from 'formik'
import Grid from '@mui/material/Grid'

import { GroupRecipientList, GroupRecipientListInit, GroupRecipientListUpdate } from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../../store'
import { formTransform, useConfirmationDialog, useUser } from '../../../utils'
import {
  clearGroupList,
  createGroupList,
  getGroupList,
  removeGroupList,
  updateGroupList,
} from '../../../redux/actions/groupListsActions'

import { ButtonsPane, Paper, SafeRouting, TextInput } from '../../common/Form'
import Pendable from '../../common/Pendable'
import Wrapper from '../../common/Wrapper'

import AvailableGroups from './AvailableGroups'
import Groups from './Groups'

import { useRoutes } from '../../../store'

const getInitialState = (
  group: string,
  selectedGroupList?: GroupRecipientList,
): GroupRecipientList & GroupRecipientListUpdate =>
  mergeWith(
    {
      name: '',
      description: '',
      group,
      addGroups: [],
      removeGroups: [],
    },
    selectedGroupList,
    (_initial, existent, key) => {
      if ('description' === key && !existent) {
        return ''
      }
    },
  )

const GroupListForm = ({
  values,
  setStatus,
  dirty,
  isSubmitting,
  setSubmitting,
}: FormikProps<GroupRecipientList & GroupRecipientListUpdate>) => {
  const routes = useRoutes()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const showConfirm = useConfirmationDialog()
  const { formErrors, saving } = useSelector(({ groupListsReducer }: GlobalState) => groupListsReducer, shallowEqual)
  useEffect(() => {
    setStatus(
      Array.isArray(formErrors) ? formErrors.reduce((acc, item) => ({ ...acc, [item.name]: item.reason }), {}) : {},
    )
  }, [formErrors])
  useEffect(() => {
    if (saving === false) setSubmitting(false)
  }, [saving])
  const [addIsShown, setAddIsShown] = useState(!values.id)
  const [groupsCollapsed, setGroupsCollapsed] = useState(false)

  const deleteGroupList = () => {
    showConfirm(
      () => void dispatch(removeGroupList(values.id)),
      `Are you sure you want to delete group list ${values.name}?`,
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={dirty && !isSubmitting} />
        <Form id="group-list-form" translate="no" noValidate>
          <Paper title="Metadata">
            <TextInput name="name" label="Name" required autoFocus />
            <TextInput name="description" label="Description" multiline />
          </Paper>
          {!!values.id && (
            <Paper
              title="Groups"
              collapsible
              collapsed={groupsCollapsed}
              actionsPane={
                addIsShown
                  ? []
                  : [
                      {
                        title: 'Add groups',
                        id: 'add-groups',
                        onClick: () => {
                          setAddIsShown(true)
                          setGroupsCollapsed(true)
                        },
                      },
                    ]
              }
            >
              <Groups />
            </Paper>
          )}
          {addIsShown && !saving && (
            <Paper title="Select groups">
              <AvailableGroups />
            </Paper>
          )}

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.groupLists()),
              },
              Save: { savingState: !!saving, primary: true, type: 'submit' },
            }}
            secondary={values.id ? { 'Remove group list': { onClick: deleteGroupList } } : undefined}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = () => {
  const { id } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    id && dispatch(getGroupList(id))
    return () => {
      dispatch(clearGroupList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { groupList } = useSelector(({ groupListsReducer }: GlobalState) => groupListsReducer, shallowEqual)
  const { group } = useUser()

  const onSubmit = (draft: Partial<GroupRecipientList & GroupRecipientListUpdate>) => {
    if (groupList)
      dispatch(
        updateGroupList({
          groupList: draft as GroupRecipientList & GroupRecipientListUpdate,
          withRedirection: true,
        }),
      )
    else dispatch(createGroupList(draft as GroupRecipientListInit & Pick<GroupRecipientListUpdate, 'addGroups'>))
  }

  return (
    <Wrapper name={['Groups', 'Lists', id ? groupList?.name : 'New']}>
      <Grid container spacing={0}>
        <Pendable pending={!!id && !groupList}>
          <Formik
            onSubmit={(values) => {
              onSubmit(formTransform(values))
            }}
            initialValues={getInitialState(group, groupList)}
            component={GroupListForm}
          />
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
