import { Route, Routes } from 'react-router-dom'

import { SUB_PATH } from '../../utils/routes'
import { NotFound } from '../common/NotFound'
import { Edit } from './Edit'
import { ListView as List } from './List'

export const Services = () => {
  return (
    <Routes>
      <Route path={SUB_PATH.UPDATE} element={<Edit />} />
      <Route index element={<List />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
