import { FC } from 'react'
import { unstable_usePrompt as usePrompt } from 'react-router-dom'
import { Beforeunload } from 'react-beforeunload'

const CONFIRM_PROMPT = 'You have some unsaved data, do you really want to leave the page?'

interface SafeRoutingProps {
  enabled: boolean
}

/**
 * Component to prompt confirmation before leaving form page
 * @param enabled
 * @constructor
 */
const SafeRouting: FC<SafeRoutingProps> = ({ enabled }) => {
  usePrompt({
    message: CONFIRM_PROMPT,
    when: enabled,
  })

  return (
    <Beforeunload
      onBeforeunload={() => {
        if (enabled) return CONFIRM_PROMPT
        return null
      }}
    />
  )
}

export default SafeRouting
