import { Route, Routes } from 'react-router-dom'

import { SUB_PATH } from '../../utils/routes'
import { NotFound } from '../common/NotFound'
import { ListView as OutputLists } from '../outputLists/List'
import { Edit as OutputListsEdit } from '../outputLists/Edit'
import { List } from './List'
import { Edit } from './Edit'

export const Outputs = () => {
  return (
    <Routes>
      <Route path={SUB_PATH.UPDATE} element={<Edit />} />
      <Route path={SUB_PATH.NEW} element={<Edit />} />
      <Route index element={<List />} />
      <Route path="lists">
        <Route index element={<OutputLists />} />
        <Route path={SUB_PATH.NEW} element={<OutputListsEdit />} />
        <Route path={SUB_PATH.UPDATE} element={<OutputListsEdit />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
