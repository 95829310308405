import { useState } from 'react'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../common/Tooltip'
import Typography from '@mui/material/Typography'
import HighlightOff from '@mui/icons-material/HighlightOff'
import { Theme } from '@mui/material/styles'

import { Group, GroupRecipientList, GroupRecipientListUpdate } from 'common/api/v1/types'
import { Api } from '../../../store'
import PaginatedList from '../../common/SelfStatePaginatedList'
import { Table } from '../../common/Table'
import { GroupsRequestParams } from '../../../api/nm-types'

const styles = {
  button: {
    color: (theme: Theme) => theme.palette.error.main,
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '48px',
    minWidth: '140px',
  },
}

const groupListsApi = Api.groupListsApi

const Groups = () => {
  const [hideFilter, setHideFilter] = useState(true)
  const { values, setFieldValue } = useFormikContext<
    GroupRecipientList & Pick<GroupRecipientListUpdate, 'removeGroups'>
  >()
  const onRemove = (id: Group['id']) => setFieldValue('removeGroups', values.removeGroups.concat(id))
  const onCancel = (id: Group['id']) =>
    setFieldValue(
      'removeGroups',
      values.removeGroups.filter((groupId) => groupId !== id),
    )

  return (
    <Grid item xs={12}>
      <PaginatedList<GroupsRequestParams, Group>
        api={function (params) {
          return groupListsApi.getGroups(values.id, true, params)
        }}
        emptyMessage="no groups"
        hideSearch={hideFilter}
        notFoundMessage="no matching groups"
        onListChange={({ filter, total = 0 }) => {
          setHideFilter(!filter && total < 10)
        }}
        searchPlaceholder="Find specific group…"
        List={({ list }) => (
          <Table
            id="selectgroups-table"
            data={list}
            isSmall
            hasBorders
            noHeader
            config={[
              {
                getValue: ({ name }) => <Typography>{name}</Typography>,
              },
              {
                getValue: ({ id }) => (
                  <Box sx={styles.actions}>
                    {values.removeGroups.includes(id) ? (
                      <Button sx={styles.button} onClick={() => onCancel(id)} variant="outlined" size="small">
                        Cancel remove
                      </Button>
                    ) : (
                      <Tooltip title="Remove from list" placement="top">
                        <IconButton edge="start" aria-label="remove" onClick={() => onRemove(id)}>
                          <HighlightOff />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                ),
                props: {
                  align: 'right',
                },
              },
            ]}
          />
        )}
      />
    </Grid>
  )
}

export default Groups
