import { Route, Routes } from 'react-router-dom'

import AlarmsTable from './Table'
import { NotFound } from '../common/NotFound'

export const Alarms = () => {
  return (
    <Routes>
      <Route index element={<AlarmsTable />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
