import MuiToolTip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'

export interface Props extends MuiTooltipProps {
  disablePortal?: boolean
}

const Tooltip = ({ children, disablePortal, ...props }: Props) => {
  return (
    <MuiToolTip
      PopperProps={{ disablePortal: disablePortal ?? true }} // disablePortal=true to avoid a second flickering scrollbar that appears if scrolling when tooltip is visible
      slotProps={{ tooltip: { sx: { textTransform: 'none' } } }}
      {...props}
    >
      {children}
    </MuiToolTip>
  )
}

export default Tooltip
