import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import Tooltip from '../common/Tooltip'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Circle from '@mui/icons-material/FiberManualRecord'
import HelpOutline from '@mui/icons-material/HelpOutline'

import { Service, ListServiceSortableField } from 'common/api/v1/types'
import { usePageParamsFiltered } from '../../utils'
import Table, { MoveToEntity, TableConfig } from '../common/Table'
import { MissingContent } from '../common/MissingContent'
import Wrapper from '../common/Wrapper'
import { useAsync } from 'react-async-hook'
import { getServices } from '../../redux/actions/serviceActions'
import { ServicesRequestParams } from '../../api/nm-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Api, AppDispatch, GlobalState } from '../../store'
import { ReduxSelectedFilters } from '../common/Filters/FilterView/SelectedFilters'
import Toolbar from '@mui/material/Toolbar'
import { mapK8sFilterToUrlParam, mapUrlParamToK8sServiceFilter } from './listServiceFilter'

import { useRoutes } from '../../store'
import { Product } from './Product'

const { serviceApi } = Api

const Toggle = ({ value }: { value: boolean }) => {
  return value ? <Circle /> : null
}

export const ListView = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const searchParams = usePageParamsFiltered<ServicesRequestParams>()
  const services = useAsync(() => getServices(searchParams, { dispatch, serviceApi }), [JSON.stringify(searchParams)])
  const routes = useRoutes()
  const tableConfig: TableConfig<Service, ListServiceSortableField> = [
    {
      title: 'product',
      getValue: ({ product }) => <Product product={product} />,
      sorting: { byParameter: ListServiceSortableField.product },
    },
    {
      title: 'purpose',
      getValue: ({ purpose, description }) => (
        <Box sx={{ display: 'flex' }}>
          {purpose}
          {description && (
            <Tooltip title={description}>
              <HelpOutline sx={{ marginLeft: 1 }} />
            </Tooltip>
          )}
        </Box>
      ),
      sorting: { byParameter: ListServiceSortableField.purpose },
    },
    {
      title: 'collect logs',
      getValue: ({ collectLogs }) => <Toggle value={collectLogs} />,
    },
    {
      getValue: (svc) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <MoveToEntity url={routes.servicesUpdate({ id: svc.id })} entityName="service" />

            {svc.filterUrl && (
              <Tooltip title="View logs">
                <span>
                  <MuiLink href={svc.filterUrl} target="_blank">
                    <IconButton edge="end" aria-label="View logs">
                      <OpenInNew />
                    </IconButton>
                  </MuiLink>
                </span>
              </Tooltip>
            )}
          </Box>
        )
      },
      props: { align: 'right' },
    },
  ]

  let data = services.result?.items ?? []
  let total = services.result?.total ?? 0
  if (!devMode) {
    // exclude loadsim
    if (total > 0) {
      total = total - 1
    }
    data = data.filter((s) => devMode || s.purpose !== 'Load simulator')
  }
  return (
    <Wrapper name="Services">
      <Toolbar disableGutters>
        <ReduxSelectedFilters
          urlParamCacheKey={'k8sServices'}
          mapUrlParamToFilterFn={mapUrlParamToK8sServiceFilter}
          mapFilterToUrlParamFn={mapK8sFilterToUrlParam}
        />
      </Toolbar>
      <Table<Service>
        emptyMessageComponent={<MissingContent message="No services available" />}
        config={tableConfig}
        data={data}
        id="services-table"
        pending={services.loading}
        actions={[]}
        pagination={{ total, useUrlSearchParams: true }}
      />
    </Wrapper>
  )
}
