import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import omit from 'lodash/omit'

import { Output, Input, MetricWindow, ServiceOverview, ListOutputSortableField } from 'common/api/v1/types'
import { ThunkApi } from '../../store'
import { withDefaultPagination } from '../../utils'
import { getLocationParams } from '../../utils/params'
import { OutputsRequestParams, PaginatedRequestParams } from '../../api/nm-types'

export const LOCAL_STORAGE_SO_ANIMATION = 'so-animations'
export const getService = createAsyncThunk<
  ServiceOverview,
  {
    params: Partial<OutputsRequestParams>
    inputId: Input['id']
    outputId: Output['id']
  },
  ThunkApi
>('serviceOverview/getService', async ({ params, inputId, outputId }, { getState, extra: { api } }) => {
  const { tr101290Window } = getState().serviceOverviewReducer
  const serviceOverview = await api.inputApi.getOverview(inputId, {
    ...withDefaultPagination(params),
    inputTr101290Window: tr101290Window,
    output: outputId,
  })
  return serviceOverview
})

export const selectTr101290Window = createAsyncThunk<void, MetricWindow, ThunkApi>(
  'serviceOverview/selectTr101290Window',
  async (_, { dispatch }) => {
    await dispatch(refresh())
  },
)
export const clearService = createAction('serviceOverviewActions/clearService')
export const refresh = createAsyncThunk<void, void, ThunkApi>(
  'serviceOverview/refresh',
  async (_, { dispatch, getState }) => {
    dispatch(
      getService({
        params: withDefaultPagination<PaginatedRequestParams<ListOutputSortableField>>(
          omit(getLocationParams(), 'outputId'),
        ),
        inputId: getState().serviceOverviewReducer.input?.id || '',
        outputId: getLocationParams().outputId,
      }),
    )
  },
)

export const setEnableAnimations = createAction('serviceOverviewActions/enableAnimations', (isEnabled: boolean) => {
  if (isEnabled) localStorage.setItem(LOCAL_STORAGE_SO_ANIMATION, 'enabled')
  else localStorage.removeItem(LOCAL_STORAGE_SO_ANIMATION)
  return { payload: isEnabled }
})
