import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import type {
  OutputRecipientList,
  OutputRecipientListInit,
  ListResult,
  OutputRecipientListUpdate,
  OutputRecipientListSortableField,
} from 'common/api/v1/types'
import { ThunkApi } from '../../store'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { withDefaultPagination } from '../../utils'
import type { EnrichedOutputRecipientList, PaginatedRequestParams } from '../../api/nm-types'

export const createOutputList = createAsyncThunk<
  OutputRecipientList,
  OutputRecipientListInit & Pick<OutputRecipientListUpdate, 'addOutputs'>,
  ThunkApi
>('outputLists/createOutputList', async (outputList, { dispatch, extra: { routes, api, navigate } }) => {
  try {
    const res = await api.outputListsApi.createOutputList(outputList)
    navigate()(routes.outputLists())
    dispatch(enqueueSuccessSnackbar(`Created output list ${outputList.name}`))
    return res
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'create output list' }))
    throw err
  }
})

export const removeOutputList = createAsyncThunk<OutputRecipientList, OutputRecipientList['id'], ThunkApi>(
  'outputLists/removeOutputList',
  async (outputListId, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      const res = await api.outputListsApi.removeOutputList(outputListId)
      navigate()(routes.outputLists())
      dispatch(enqueueSuccessSnackbar(`Removed output list: ${res.name}`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'delete output list' }))
      throw err
    }
  },
)

export const updateOutputList = createAsyncThunk<
  OutputRecipientList,
  {
    outputList: OutputRecipientList & OutputRecipientListUpdate
    withRedirection: boolean
  },
  ThunkApi
>(
  'outputLists/updateOutputList',
  async ({ outputList, withRedirection }, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      const res = await api.outputListsApi.updateOutputList(outputList.id, outputList)
      withRedirection && navigate()(routes.outputLists())
      dispatch(enqueueSuccessSnackbar(`Edited output list: ${outputList.name}`))
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update output list' }))
      throw err
    }
  },
)

export const getOutputLists = createAsyncThunk<
  ListResult<EnrichedOutputRecipientList>,
  Partial<PaginatedRequestParams<OutputRecipientListSortableField>>,
  ThunkApi
>('outputLists/getOutputLists', async (params, { dispatch, extra: { api } }) => {
  try {
    return await api.outputListsApi.getOutputLists(withDefaultPagination(params))
  } catch (err) {
    dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch output lists' }))
    throw err
  }
})

export const getOutputList = createAsyncThunk<EnrichedOutputRecipientList, OutputRecipientList['id'], ThunkApi>(
  'outputLists/getOutputList',
  async (id, { dispatch, extra: { api } }) => {
    try {
      return await api.outputListsApi.getOutputList(id)
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'fetch output list' }))
      throw err
    }
  },
)

export const clearOutputList = createAction('outputLists/clearOutputList')
export const clearOutputLists = createAction('outputLists/clearOutputLists')
