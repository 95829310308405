import { Route, Routes } from 'react-router-dom'

import { NotFound } from '../common/NotFound'
import AlarmLogList from './List'

export const AlarmLogs = () => {
  return (
    <Routes>
      <Route index element={<AlarmLogList />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
