import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from '../../store'
import { IpMapping, IpMappingRead, IpMappingWrite } from 'common/api/v1/types'

import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from './notificationActions'
import { MappingKey } from '../../api/addressMappings/api'
import { withDefaultPagination } from '../../utils'
import { IpMappingsRequestParams } from '../../api/nm-types'

const prefix = 'addressMapping'

export const createIpMapping = createAsyncThunk<void, IpMappingWrite, ThunkApi>(
  `${prefix}/createIpMapping`,
  async (ipMapping, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      await api.addressMappingsApi.setIpMapping(ipMapping)
      navigate()(routes.addressMappings())
      dispatch(enqueueSuccessSnackbar(`Created ip mapping for ${ipMapping.privateIp}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'create ip mapping' }))
      throw err
    }
  },
)

export const updateIpMapping = createAsyncThunk<void, IpMappingWrite, ThunkApi>(
  `${prefix}/updateIpMapping`,
  async (ipMapping, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      await api.addressMappingsApi.updateIpMapping(ipMapping)
      navigate()(routes.addressMappings())
      dispatch(enqueueSuccessSnackbar(`Update ip mapping for ${ipMapping.privateIp}`))
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'update ip mapping' }))
      throw err
    }
  },
)

export const getIpMappings = createAsyncThunk<IpMappingRead[], Partial<IpMappingsRequestParams>, ThunkApi>(
  `${prefix}/getIpMappings`,
  async (params, { extra: { api } }) => {
    return await api.addressMappingsApi.getIpMappings(withDefaultPagination(params))
  },
)

export const getIpMapping = createAsyncThunk<IpMappingRead, MappingKey, ThunkApi>(
  `${prefix}/getIpMapping`,
  async (key, { extra: { api } }) => {
    return await api.addressMappingsApi.getIpMapping(key)
  },
)

export const removeIpMapping = createAsyncThunk<IpMapping[], IpMappingWrite, ThunkApi>(
  `${prefix}/removeIpMapping`,
  async (ipMapping, { dispatch, extra: { routes, api, navigate } }) => {
    try {
      const res = await api.addressMappingsApi.removeIpMapping(ipMapping)
      dispatch(enqueueSuccessSnackbar(`Removed ip mapping: ${ipMapping.privateIp}`))
      navigate()(routes.addressMappings())
      return res
    } catch (err) {
      dispatch(enqueueErrorSnackbar({ error: err, operation: 'remove ip mapping' }))
      throw err
    }
  },
)

export function writeModelFromReadModel(ipMapping: IpMappingRead | IpMappingWrite): IpMappingWrite {
  const { internalIp, interRegionIp, privateIp, publicIp, region } = ipMapping
  return { internalIp, interRegionIp, privateIp, publicIp, region: typeof region === 'string' ? region : region.id }
}

export const clearIpMappings = createAction<void>(`${prefix}/clearIpMappings`)
export const clearEditMapping = createAction<void>(`${prefix}/clearEditMapping`)
